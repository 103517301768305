<div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-auto">
            <h1 class="m-0 pl-4 subtitle">Módulo Cargas Masivas Elementos</h1>
          </div>
          <div class="col-sm-6 mt-1 border-left border-right">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a href="#">Carga Masiva</a></li>
              <li class="breadcrumb-item active">Elementos</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <section class="col-lg-12 pl-3 pr-3 connectedSortable">
            <div class="card  box">
              <div class="row p-3">
                <div class="col-12">
                  <h5>Cargar Archivo</h5>
                  <div class="box-body">
                    <div class="form-group">
                        <label>Seleccione el archivo a subir</label><br>
                        <input type="file" (change)="fileChange($event)" placeholder="Subir archivo" accept=".xls,.xlsx">
                    </div>
                    <div class="form-group" *ngIf="uploadingFile">
                        <i class="fa fa-spin fa-refresh fa-2x"></i>
                        Subiendo archivo, por favor espere...
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <!-- /.Left col -->
          <!-- right col (We are only adding the ID to make the widgets sortable)-->
          <!-- right col -->
        </div>
        <!-- /.row (main row) -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
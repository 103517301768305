import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Clientes } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { RegionesModalComponent } from '../admi-regiones/regiones-modal/regiones-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClientesModalComponent } from './clientes-modal/clientes-modal.component';
import { FormatoRutPipe } from '../../core/pipes/formato-rut.pipe'
import { RutService } from '../../core/services/http/rut.service';
import { Ng2Rut } from 'ng2-rut';
//exportar a excel
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//exportar a pdf
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-admi-clientes',
  templateUrl: './admi-clientes.component.html',
  styleUrls: ['./admi-clientes.component.scss']
})
export class AdmiClientesComponent implements OnInit {

  cliente: Clientes[]
  clientesV2: any[];
  myGroup: FormGroup;
  datosInterface: Clientes;
  selectedProducts: Clientes[];
  modalRef: BsModalRef;
  ListaRoles: [] = [];
  ListaOFicinas: [] = [];
  registerForm: FormGroup;
  rutValid: boolean;
  // exportación
  columns: any[] = [];
  exportColumns: any[];
  cols: any[];
  fileName= 'Usuarios.xlsx';
  loading;
  filteredValues: any;
  sesionrol;

  constructor(
    private PruebasService: PruebasService,
    private modalService: ModalService,
    private Router: Router,
    private RutService:RutService,
    private rutValidator: Ng2Rut,
    private SweetAlertService: SweetAlertService,
    private FormatoRutPipe: FormatoRutPipe) { }

  async ngOnInit() {
    this.sesionrol = localStorage.getItem('rolsesion')
    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }

  get f() { return this.registerForm.controls; }

  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  async iniciarDatos()
  {
    this.myGroup = new FormGroup({
      sRut: new FormControl(),
      sNom: new FormControl(),
      sClave: new FormControl(),
      eUsuario: new FormControl(),
      sIdRol: new FormControl()
    });
  }

  async traerdatos(datos) {
    console.log(datos);
    var reqCliente = {
      "dniUsu": datos.sDniUsu,
      "nomUsu": datos.sNombreUsu,
      "sPassusu": datos.sPassUsu,
      "bHab": datos.bHab,
      "idRol": datos.idRol,
      "idOficina": datos.idOficina,
      "cantElemt":datos.cantElemt
    }
    //this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.SweetAlertService.swalLoading();
    //console.log("REQCLIENTE: ", reqCliente);
    
    var resp = await this.PruebasService.ActualizarCliente(reqCliente);
    if (resp["status"]) {
      await this.llenarTabla();
      await this.SweetAlertService.swalUsuarioActualizado("Éxito","Se ha modificado el cliente");
    }
    else {
      this.SweetAlertService.swalError("No se pudieron realizar los cambios. Intente nuevamente");
    }
  }

  async EliminarCliente(datos:Clientes) {
    var confirma = await this.SweetAlertService.swalEliminar("¿Desea Eliminar es cliente?","No se podrá revertir ésta acción")
  if (confirma == true ) {
    //console.log(datos);
    var reqCliente = datos.sDniUsu
    this.SweetAlertService.swalLoading();
    var resp = await this.PruebasService.EliminarClientes(reqCliente);
    if (resp["status"]) {
      await this.llenarTabla();
      await this.SweetAlertService.swalUsuarioEliminado("Éxito","Se ha eliminado correctamente el cliente");
    }
    else {
      this.SweetAlertService.swalError("Error, intente nuevamente...");
    }
  }
    
  }

  async llenarTabla()
  {
    this.SweetAlertService.swalLoading();
    let response = await this.PruebasService.TraerClientesMethanext();
    let respon2 = await this.PruebasService.TraerOficinas();
    let respon = await this.PruebasService.TraerRoles();
    //console.log("Usuarios:", response);
    //console.log("Oficinas:", respon2);
    //console.log("Roles:", respon);
    if (response['status'] && respon2['status'] && respon['status']) {
      this.clientesV2 = response['data'];
      this.filteredValues = this.clientesV2;
      this.ListaOFicinas = respon2['data'];
      this.ListaRoles = respon['data'];

      var dataTable = this.clientesV2.map((value: any) => ({
        ...value,
        NombreOfi: this.ListaOFicinas.find(
          (x: any) => value.idOficina == x.idOficina
        )["sOficina"],NombreRol: this.ListaRoles.find(
          (x: any) => value.idRol == x.idRol
        )["descripcion"]
      }));

      this.clientesV2 = dataTable;
      
      console.log(this.clientesV2);
      
    }
    this.SweetAlertService.swalClose();
  }

  Seleccionar()
  {
    const opcion = {class: 'w-50', initialState: {regiones: this.clientesV2}}
    this.modalRef = this.modalService.openModal(ClientesModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      //console.log('result: ', result);
      if(result){
        this.llenarTabla()
      }

    });
  }

  validaRut(datos: Clientes): void 
  {

    var rut = datos.sDniUsu;
    //console.log(this.registerForm.value.sRut);
    
    this.rutValid = this.RutService.validarRut(this.registerForm.value.rut.replace(/\./g, '').replace('-', ''));
    this.registerForm.patchValue({ rut: this.RutService.formateaRut(this.registerForm.value.rut) });
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[A-Za-zÑ-ñ, ]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
  }

  onFilter(event, dt) { 
    this.filteredValues = event.filteredValue; 
    console.log("FILTRO: ", this.filteredValues.length);
  }

  //EXPORTAR A EXCEL (SE FILTRAN LOS DATOS A IMPRIMIR)
  exportExcel() {
    var datos = [];
    var toExport = [];
    if(this.filteredValues)
    {
      datos = this.filteredValues;
      for (let element of datos) {
        var obj = {
          'IdRol':element.idRol,
          'RUT': element.sDniUsu,
          'Nombre': element.sNombreUsu,
          'Oficina': element.idOficina,
          'Estado': element.bHab==true?"Habilitado":"Deshabilitado"
        }
        toExport.push(obj);
        console.log("QUE TRAE FILTER: ", obj);
      }
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(toExport);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Usuarios");
      });
    }
    else
    {
      datos = this.clientesV2;
      for (let element of datos) {
        var obj = {
          'IdRol':element.idRol,
          'RUT': element.sDniUsu,
          'Nombre': element.sNombreUsu,
          'Oficina': element.idOficina,
          'Estado': element.bHab==true?"Habilitado":"Deshabilitado"
        }
        toExport.push(obj);
        console.log("QUE TRAE FILTER: ", obj);
      }
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(toExport);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Usuarios");
      });
    }
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  //EXPORTAR A PDF
  exportPdf() {
    var datos = [];
    var toExport = [];
    datos = this.filteredValues;
    for (let element of datos) {
      var obj = {
        'IdRol':element.idRol,
        'RUT': element.sDniUsu,
        'Nombre': element.sNombreUsu,
        'Oficina': element.idOficina,
        'Estado': element.bHab==true?"Habilitado":"Deshabilitado"
      }
      toExport.push(obj);
    }
     var doc = new jsPDF('p', 'pt');
     doc.text("Usuarios",50, 30);
      autoTable(doc, {columns: this.exportColumns,body: toExport})
      doc.save('Usuarios.pdf');
  }
}

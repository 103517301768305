<!-- main app container -->
<div class="card m-3">
    <h5 class="card-header" class="text-secondary">Añadir Usuarios Oficina</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Oficina</label>
                <input type="number"  formControlName="iOfi" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Rut Usuario</label>
                <input type="string"  formControlName="rUsu" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Contraseña</label>
                <input type="password"  formControlName="uPass" class="form-control" >
            </div>                   
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarUsuarioOfi()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <button (click)="volver()">Volver</button>
    <h5>Admi Bodegas</h5>
    <p>Para modificar presione el nombre en la tabla.</p>
    <button type="submit" class="btn btn-primary btn-block" (click)="Seleccionar()">Registrar</button>
    <div *ngIf="bodegasV2" style="height: 700px;">
  
      <p-table #dt1 [value]="bodegasV2" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['idBodega','sBodega']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Palabra clave" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                ID Bodega
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Nombre de Bodega
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Editar
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Eliminar
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr>
            <td>
              {{customer.idBodgea}}
            </td>
            <td pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="customer.sBodega">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{customer.sBodega}}
                  </ng-template>
                </p-cellEditor>
            </td>
            <td (click)="traerdatos(customer)" class="pointer">
              Actualizar
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="10">No hay Zonas para mostrar.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br>
    <br>
    <br>
    <br>
  
  </div>

<div class="d-flex  flex-column baseqr">
    <div class="qrimg"></div>

    <div class="panttras" *ngIf="Retiroclave == true"></div>
    <div class="modal-clave" *ngIf="Retiroclave == true">
        <div class="font-weight-bold mb-2 titulo">!Ingrese su clave de pedido¡</div>
        <div><span>La clave asociada es el numero que aparece por debajo de su codigo de barra</span></div>
        <input type="text" class="admtext form-control mt-5 mb-5" placeholder="INGRESE SU Clave" [formControl]="user"  (focus)="desplegarRut('inputUser')"> 
        <div *ngIf="false" class="invalid-feedback">
          <i class="far fa-times-circle"></i> Por favor, ingrese un rut válido
        </div>
        <button class="btn btnl" (click)="CompararClave()">
            Aceptar
        </button>
    </div>
    
    <div class="textoQR"><span>Por Favor acerque al escaner el QR que llego a su Correo para retirar el pedido</span></div>
</div>

<button class="btn btnl btnv" (click)="volver()">
    Volver
</button>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { Clientes } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-casilleros-modal',
  templateUrl: './casilleros-modal.component.html',
  styleUrls: ['./casilleros-modal.component.scss']
})
export class CasillerosModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  //@Input() clientes;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  iniciarDato() {
    this.registerForm = new FormGroup({
      idloc: new FormControl(),
      idofi: new FormControl(),
      idcasi: new FormControl(),
      idtip: new FormControl(),
      idalto: new FormControl(),
      idanch: new FormControl(),
      idlar: new FormControl(),
      idest: new FormControl()
    });
  }

  obtenerdatos() {
    console.log(this.registerForm.value);
    this.datosInterface = {
      "acc": "N",
      "idL": parseInt(this.registerForm.value.idloc),
      "idO": parseInt(this.registerForm.value.idofi),
      "idCa": parseInt(this.registerForm.value.idcasi),
      "tipo": this.registerForm.value.idtip,
      "alto": parseInt(this.registerForm.value.idalto),
      "ancho": parseInt(this.registerForm.value.idanch),
      "largo": parseInt(this.registerForm.value.idlar),
      "estado": this.registerForm.value.idest
    }
    console.log('Datos Interfaz: ', this.datosInterface);
  }

  async agregarCasillero() {

    await this.obtenerdatos();
    console.log(this.datosInterface);

    let datosRetornados = await this.PruebasService.ActualizarCasillero(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito","¡Casillero Creado!");
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se pudo crear al cliente, intente nuevamente 🙂!");
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProcessService } from '../../../core/services/http/data-process.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() step: string;
  urgencia = false;
  ayuda = false;
  sitio = "";
  data: any;
  localData: any;

  constructor(
    private router:Router,
    private dataProcessService: DataProcessService
    ) { }

  ngOnInit(): void {
    this.data = this.dataProcessService.getDataProcess();
    this.localData = localStorage.getItem('rut')
    console.log("RUT: ", this.data["rut"]);
    console.log("RUT LOCAL STORAGE: ", this.localData);
    
    
  }

  RenviarCorreo(){
    this.router.navigate(['reenviar-correo']);
  }

  redireccionReporteriaCierre()
  {
    this.router.navigate(['cierre']);
  }
  redireccionReporteriaLog()
  {
    this.router.navigate(['reporte-log']);
  }

  redireccionParaPedido()
  {
    this.router.navigate(['NuevoPedido']);
  }
  Dashboard()
  {
    this.router.navigate(['MenuOpciones']);
  }
  redireccionParaRetiro()
  {
    this.router.navigate(['RetirarPedido'],{ queryParams: { 'idcasillero': '5', 'idLocker': '5', 'idOficina':'5' } });
  }
  redireccionTablaPedidos()
  {
    this.router.navigate(['TablaPedido']);
  }
  redireccionTablaZona()
  {
    this.router.navigate(['TablaZona']);
  }
  redireccionTablaRegiones()
  {
    this.router.navigate(['TablaRegiones']);
  }
  redireccionTablaComunas()
  {
    this.router.navigate(['TablaComunas']);
  }
  redireccionTablaOficinas()
  {
    this.router.navigate(['TablaOficinas']);
  }
  redireccionTablaBodegas()
  {
    this.router.navigate(['TablaBodegas']);
  }
  redireccionTablaClientes()
  {
    this.router.navigate(['TablaClientes']);
  }
  redireccionTablaUsuarios()
  {
    this.router.navigate(['TablaUser']);
  }
  redireccionTablaLockers()
  {
    this.router.navigate(['TablaLockers']);
  }
  redireccionTablaCasilleros()
  {
    this.router.navigate(['TablaCasilleros']);
  }
  redireccionTablaUsuariosOfi()
  {
    this.router.navigate(['TablaUsuariosOfi']);
  }
  redireccionTipoElemento()
  {
    this.router.navigate(['TipoElemento']);
  }
  redireccionElemento()
  {
    this.router.navigate(['Elemento']);
  }
  redireccionHome()
  {
    this.router.navigate(['']);
  }

  //MethaNext
  redireccionMasivaElementos()
  {
    this.router.navigate(['CargaMasivaElementos']);
  }
  redireccionMasivaUsuarios()
  {
    this.router.navigate(['CargaMasivaUsuarios']);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { Comunas } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-comunas-modal',
  templateUrl: './comunas-modal.component.html',
  styleUrls: ['./comunas-modal.component.scss']
})
export class ComunasModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  //@Input() regiones;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  iniciarDato() {
    this.registerForm = new FormGroup({
      iCom: new FormControl(),
      iReg: new FormControl(),
      nCom: new FormControl()
    });
  }

  obtenerdatos() {
    console.log(this.registerForm.value);
    this.datosInterface = {
        "acc": "N",
        "idC": this.registerForm.value.iCom,
        "idR": this.registerForm.value.iReg,
        "comuna": this.registerForm.value.nCom
      }
      console.log('Datos Interfaz: ', this.datosInterface);
    }
    
    async agregarComuna() {

    await this.obtenerdatos();
    console.log(this.datosInterface);
    
    let datosRetornados = await this.PruebasService.crudComunas(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito","¡Comuna creada!");    
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se puedo crear la comuna, intente nuevamente 🙂!");
    }
  }


}

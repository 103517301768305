import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { UsuariosOfi } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admi-usu-modal',
  templateUrl: './admi-usu-modal.component.html',
  styleUrls: ['./admi-usu-modal.component.scss']
})
export class AdmiUsuModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  //@Input() usuOfi;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  iniciarDato() {
    this.registerForm = new FormGroup({
      iOfi: new FormControl(),
      rUsu: new FormControl(),
      uPass: new FormControl()
    });
  }
  obtenerdatos() {
    console.log(this.registerForm.value);
    this.datosInterface = {
      "acc": "N",
      "idO": this.registerForm.value.iOfi,
      "dniUsu": this.registerForm.value.rUsu,
      "password": this.registerForm.value.uPass
    }
    console.log('Datos Interfaz: ', this.datosInterface);
  }
    async agregarUsuarioOfi() {

    await this.obtenerdatos();
    console.log(this.datosInterface);
    
    let datosRetornados = await this.PruebasService.crudUsuariosOfi(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito","¡Usuario creado!");    
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se puedo crear el usuario, intente nuevamente!");
    }
  }
}

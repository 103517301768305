import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { Clientes } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { RutService } from '../../../core/services/http/rut.service';
import { TipoElemento } from '../../../customer';

@Component({
  selector: 'app-clientes-modal',
  templateUrl: './clientes-modal.component.html',
  styleUrls: ['./clientes-modal.component.scss']
})
export class ClientesModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  rutValid: boolean = false;
  datosInterface;
  onClose: Subject<boolean> = new Subject();
  //@Input() clientes;
  ListaRoles: [] = [];
  ListaOficinas: [] = [];


  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private RutService:RutService, 
    private SweetAlertService: SweetAlertService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.SweetAlertService.swalLoading();
    this.iniciarDato();
    await this.obtenerRoles();
    await this.obtenerOficinas();
    this.SweetAlertService.swalClose();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  async obtenerRoles() {
    var tipoRol = await this.PruebasService.TraerRoles();
    if (tipoRol["status"] == true) {
      this.ListaRoles = tipoRol["data"];
    }
    else{
      this.SweetAlertService.swalClose();
      this.SweetAlertService.swalError("no se pueden crear elementos en este momento, vuelva a intentar");
    }
  }

  async obtenerOficinas()
  {
    var tipoOficina = await this.PruebasService.TraerOficinas();
    if (tipoOficina["status"] == true) {
      this.ListaOficinas = tipoOficina["data"];
      console.log("USUARIOS PARA OFICINA: ", this.ListaOficinas);
      
    }
    else{
      this.SweetAlertService.swalClose();
      this.SweetAlertService.swalError("no se pueden crear elementos en este momento, vuelva a intentar");
    }
  }

  async iniciarDato() {
    this.registerForm = this.formBuilder.group({
      sIdRol: [null, Validators.required],
      sRut: ['', Validators.required],
      sNom: ['', Validators.required],
      sClave: ['', Validators.required],
      eUsuario: [null, Validators.required],
      idOfi: [null, Validators.required],
    });
  }

  obtenerdatos() {
    console.log(this.registerForm.value);
    this.datosInterface = {
      "dniUsu": this.registerForm.value.sRut.replaceAll('.',''),
      "nomUsu": this.registerForm.value.sNom,
      "sPassusu": this.registerForm.value.sClave,
      "bHab": this.registerForm.value.eUsuario,
      "idRol": this.registerForm.value.sIdRol,
      "idOficina": this.registerForm.value.idOfi
    }
    console.log('Datos Interfaz: ', this.datosInterface);
  }

  async agregarCliente() {

    this.submitted = true;
    if(this.registerForm.invalid){return;}

    this.SweetAlertService.swalLoading();
    await this.obtenerdatos();
    console.log(this.datosInterface);
    let datosRetornados = await this.PruebasService.AgregarClientesMethanext(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    this.SweetAlertService.swalClose();
    if (datosRetornados.status == true) {
      await this.SweetAlertService.swalSuccess("Éxito","¡Cliente Creado!");
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se pudo crear al cliente, intente nuevamente!");
    }
  }

  validaRut(): void {
    this.rutValid = this.RutService.validarRut(this.registerForm.value.sRut.replace(/\./g, '').replace('-', ''));
    this.registerForm.patchValue({ sRut: this.RutService.formateaRut(this.registerForm.value.sRut) });
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[A-Za-zÑ-ñ, ]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
    }

}

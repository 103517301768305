import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Regiones} from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { RegionesModalComponent } from '../admi-regiones/regiones-modal/regiones-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admi-regiones',
  templateUrl: './admi-regiones.component.html',
  styleUrls: ['./admi-regiones.component.scss']
})
export class AdmiRegionesComponent implements OnInit {

   regiones: Regiones[]
   regionesV2: [] = [];
   myGroup: FormGroup;
   datosInterface: Regiones;
   selectedProducts: Regiones[];
   modalRef: BsModalRef;

   loading;

  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {

    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
 }
 volver() {
  this.Router.navigate(['MenuOpciones']);
}

iniciarDatos()
{
  this.myGroup = new FormGroup({
    idRe: new FormControl(),
    idZo: new FormControl(),
    sReg: new FormControl(),
    idOrden: new FormControl()
   }); 
}

async traerdatos(datos) {
  console.log(datos);
  var reqRegion = {
    "acc": "U",
    "idR": datos.idRegion,
    "idZ": datos.idZona,
    "region": datos.sRegion,
    "idOrd": datos.iOrden
  }
  var resp = await this.PruebasService.ActualizarRegion(reqRegion);
  if (resp["status"]) {
    this.regionesV2 = resp['data'];
    console.log(resp['data']);

  }
  else {

  }
}

async agregarDatos(datos) {
  console.log(datos);
  var reqRegion = {
    "acc": "U",
    "idR": datos.idRegion,
    "idZ": datos.idZona,
    "region": datos.sRegion,
    "idOrd": datos.iOrden
  }
  var resp = await this.PruebasService.ActualizarRegion(reqRegion);
  if (resp["status"]) {
    this.regionesV2 = resp['data'];
    console.log(resp['data']);

  }
  else {

  }
}

async llenarTabla()
{
  let response = await this.PruebasService.traerRegion();
  if (response['status']) {
    this.regionesV2 = response['dataReg'];
    console.log("Regiones:", this.regionesV2);
  }
}

Seleccionar()
{
  const opcion = {class: 'w-50', initialState: {regiones: this.regionesV2}}
  this.modalRef = this.modalService.openModal(RegionesModalComponent, opcion);
  this.modalRef.content.onClose.subscribe((result) => {
    console.log('result: ', result);
    if(result){
      this.llenarTabla()
    }

  });
}

}

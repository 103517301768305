import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {SweetAlertService} from '../../core/services/sweet-alert/sweet-alert.service';
import {PruebasService} from '../../core/services/http/pruebas.service';
import { ConfigService } from 'src/app/core/services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import {RetirarPedido} from '../../customer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-retirar-pedido',
  templateUrl: './retirar-pedido.component.html',
  styleUrls: ['./retirar-pedido.component.scss']
})
export class RetirarPedidoComponent implements OnInit {

  myGroup: FormGroup;
  password = new FormControl('');
  submitted: boolean = false;
  registerForm: FormGroup;
  datosInterface : RetirarPedido;

  constructor(private SweetAlertService: SweetAlertService, private configService: ConfigService, 
    private httpClient: HttpClient, private PruebasService: PruebasService,
    private modalService: ModalService, private formBuilder: FormBuilder, private router: Router) { }
    
  ngOnInit(): void {
    this.iniciarDato();
  }

  volver() {
    this.router.navigate(['MenuOpciones']);
  }


  iniciarDato()
  {
    this.myGroup = new FormGroup({
      passWrd : new FormControl(),
      idCasille : new FormControl(),
      idLock : new FormControl(),
      idOfic : new FormControl()
    });
  }

  getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    console.log("Datos URL: ", results)
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

  get f() { return this.registerForm.controls; }

  obtenerdatos(){
    console.log(this.myGroup.value);
    this.datosInterface = {
      idCasillero: parseInt(this.getParameterByName('idcasillero')),
      idLocker: parseInt(this.getParameterByName('idLocker')),
      idOficina: parseInt(this.getParameterByName('idOficina')),
      //clave : 0
      clave: parseInt(this.myGroup.value.passWrd)
    }
    console.log('Datos Interfaz: ', this.datosInterface);
  }

  async retirarPedido()
  {
    this.obtenerdatos();
    
    
    console.log('FORMULARIO: ', this.datosInterface);
    let datosRetornados = await this.PruebasService.RetirarPedido(this.datosInterface)
    console.log('RESPONSE: ',datosRetornados);
    if(datosRetornados.status == true)
    {
      await this.SweetAlertService.swalSuccess("Éxito","¡Gracias por Confiar en nosotros, vuelva pronto!");
      this.router.navigate(['MenuOpciones']);
    }
    else if (datosRetornados.code == 401)
    {
      await this.SweetAlertService.swalError("¡Error, ID OFICINA para la esede, ebevuelo ");
    }
    else if (datosRetornados.code == 402)
    {
      await this.SweetAlertService.swalError("¡Error, ID LOCKER no habilitado!");
    }
    else if (datosRetornados.code == 403)
    {
      await this.SweetAlertService.swalError("¡Error, ID LOCKER no habilitado!");
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Zonas } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { ZonaModalComponent } from '../admi-zonas/zona-modal/zona-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';



@Component({
  selector: 'app-admi-zonas',
  templateUrl: './admi-zonas.component.html',
  styleUrls: ['./admi-zonas.component.scss']
})
export class AdmiZonasComponent implements OnInit {
  zonas: Zonas[]
  zonasV2: Zonas[] = [];
  myGroup: FormGroup;
  datosInterface: Zonas;
  selectedProducts: Zonas[];
  modalRef: BsModalRef;

  //Datos tabla
  loading;

  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
    
  }

  TablaUser(): void {
    //e.preventDefault();
    this.Router.navigate(['VistaModal']);
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos()
  {
    this.myGroup = new FormGroup({
      idZon : new FormControl(),
      sZonas : new FormControl(),
     }); 
  }

  async traerdatos(datos) {
    console.log(datos);
    var reqZona = {
      "acc": "U",
      "idZ": datos.idZona,
      "zona": datos.sZona
    }
    var resp = await this.PruebasService.ActualizarZona(reqZona);
    if (resp["status"]) {
      this.zonasV2 = resp['data'];
      console.log(resp['data']);
      this.SweetAlertService.swalSuccess("Éxito", "¡Zona Actualizada Correctamente!");   

    }
    else {

    }
  }

  async agregarDatos(datos) {
    console.log(datos);
    var reqZona = {
      "acc": "N",
      "idZ": datos.idZona,
      "zona": datos.sZona
    }
    var resp = await this.PruebasService.ActualizarZona(reqZona);
    if (resp["status"]) {
      this.zonasV2 = resp['data'];
      console.log(resp['data']);

    }
    else {

    }
  }

  async llenarTabla()
  {
    let response = await this.PruebasService.traerZona();
    if (response['status']) {
      this.zonasV2 = response['dataZon'];
      console.log("Zonas:", this.zonasV2);
    }
  }

  deleteSelected(){
    this.zonas.forEach(function(item) {
        const index = this.Zonas.indexOf(item);
        this.Zonas.splice(0, 1);
    });
}

  async eliminarDatos(datosDelete) {
    console.log(datosDelete);
    var reqZonaD = {
      "acc": "D",
      "idZ": datosDelete.idZona = null,
      "zona": datosDelete.sZona = null
    }
    var resp = await this.PruebasService.ActualizarZona(reqZonaD);
    if (resp["status"]) {
      this.zonasV2 = resp['data'];
      console.log(resp['data']);
    }
  }

  Seleccionar()
  {
    const opcion = {class: 'w-50', initialState: {zonas: this.zonasV2}}
    this.modalRef = this.modalService.openModal(ZonaModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if(result){
        this.llenarTabla()
      }

    });
  }

  



}
<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <h5 class="card-header" class="text-secondary">Añadir Casillero</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Locker</label>
                <input type="text"  formControlName="idloc" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Oficina</label>
                <input type="text"  formControlName="idofi" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Casillero</label>
                <input type="text"  formControlName="idcasi" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Tipo</label>
                <input type="text"  formControlName="idtip" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Alto</label>
                <input type="text"  formControlName="idalto" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Ancho</label>
                <input type="text"  formControlName="idanch" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Largo</label>
                <input type="text"  formControlName="idlar" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Estado</label>
                <input type="text"  formControlName="idest" class="form-control" >
            </div>                
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarCasillero()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <div class="textoSelect"><span>¿Que tipo de accion desea realizar?</span></div>
    <div class="d-flex row textoSelect justify-content-between">
        <div class="w-50 d-flex  flex-column align-items-center">
            <div class="ImgRetirar BgImgSct"><i class="pi pi-download "></i></div>
            <span>Retirar Pedido</span>
        </div>
        <div class="w-50 d-flex  flex-column align-items-center"  (click)="IngresarPedido()">
            <div class="ImgIngresar BgImgSct"><i class="pi pi-upload "></i></div>
            <span>Ingresar Pedido</span>
        </div>
    </div>
</div>

<button class="btn btnl btnv" (click)="volver()">
    Volver
</button>

import { Component, OnInit } from '@angular/core';
import { interval, Subscription, from } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { CanActivate, Router } from '@angular/router';
import { ApiLockerService } from '../../../core/services/http/api-locker.service';
import { ValidatorsService } from '../../../core/services/miscellaneous/validators.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { ModalTecladoService } from 'src/app/core/services/modals/modal-teclado.service';
import { KeyboardComponent } from 'src/app/custom/keyboard/keyboard.component';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { FormControl } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-escan-rut',
  templateUrl: './escan-rut.component.html',
  styleUrls: ['./escan-rut.component.scss']
})
export class EscanRutComponent implements OnInit {

  CodigoBarra = "";
  pedidos;
  fecha;
  clave;
  subscription: Subscription = new Subscription();
  user = new FormControl('');
  Retiroclave = false;
  pedidoencontrado;

  rutvalido;

  constructor(
    private ApiLockerService: ApiLockerService,
    private ValidatorsService: ValidatorsService,
    private SweetAlertService: SweetAlertService,
    private Router: Router,
    private modalTecladoService: ModalTecladoService,
    private emitTecladoService: EmitTecladoService) { }

  ngOnInit(): void {
    this.keyboardSubscription();

    var esto = this;
    window.dispatchEvent(new Event('resize'));
    $(document).ready(function () {
      //console.log("ready!");

      $(document).scannerDetection({
        timeBeforeScanTest: 500, // wait for the next character for upto 200ms
        avgTimeByChar: 200, // it's not a barcode if a character takes longer than 100ms
        onComplete: function (barcode, qty) {
          esto.SweetAlertService.swalLeyendoQR();
          esto.UsarQR(barcode);
        } // main callback function	
      });
    });
  }

  async UsarQR(barcode) {
    this.CodigoBarra = barcode;
    if (this.CodigoBarra != "") {
      var validacedula = "";
      var rut
      validacedula = this.CodigoBarra.substring(0, 3);
      if (this.CodigoBarra.length < 13) {
        if (this.CodigoBarra.includes("'")) {
          rut = this.CodigoBarra.split("'");
          this.AsignrValorRut(rut[0]+rut[1]);
        }
        else if (this.CodigoBarra.includes('-')) {
          rut = this.CodigoBarra.split("-");
          this.AsignrValorRut(rut[0]+rut[1]);
        }
      }
      else if (validacedula == "htt") {
        //PARA ANTIGUA CEDULA DE IDENTIDAD QR
        this.analizar_cedula_nuevaQR(this.CodigoBarra);
      }
      else if (this.CodigoBarra.length > 100) {
        //PARA ANTIGUA CEDULA DE IDENTIDAD QR
        this.analizar_rut_antiguoQR(this.CodigoBarra);
      }
      else if (validacedula.toUpperCase() == "IDC") {
        //PARA ANTIGUA CEDULA DE IDENTIDAD OCR
        this.analizar_rut_antiguoOCR(this.CodigoBarra);
      }
      else {
        //PARA NUEVA CEDULA DE IDENTIDAD OCR
        this.analizar_cedula_nuevaOCR(this.CodigoBarra);
      }
    }
    else{
      this.SweetAlertService.swalErrorgeneral("Error QR","Ha ocurrido un error al realizar la lectura del QR");
    }
  }


  gethoras() {
    let date = new Date()
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hora = (date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours())
      + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes())
      + ":" + (date.getSeconds() < 10 ? "0" + date.getSeconds() : "" + date.getSeconds())
      + "." + date.getUTCMilliseconds();
    if (month < 10) {
      this.fecha = `${year}-0${month}-${day}T${hora}`;
    } else {
      this.fecha = `${year}-${month}-${day}T${hora}`;
    }

    //console.log(this.fecha);
  }

  analizar_cedula_nuevaOCR(barcode: string) {
    //dato1 = "inchl1088752229s50;;;;;;;;;;;;6505172m2505174chl9251277;0;;2herlinger;kaufmann;;ilan;;;;;;;";
    //string validacedula, rutvalidador;
    var Rut;
    var rutvalidador;
    var ncrutm = barcode.substring(48,56);
    var verificarguion = barcode.substring(56, 57);
    var ndrutm = barcode.substring(57, 58);
    if (verificarguion == ";" || verificarguion == "<") {
      //Para rut mayor a 10 mill
      Rut = ncrutm + "-" + ndrutm;
      this.AsignrValorRut(ncrutm+ndrutm);     
    }
    else {
      //Para rut menor a 10 mill
      ncrutm = barcode.substring(48, 55);
      verificarguion = barcode.substring(55, 56);
      ndrutm = barcode.substring(56, 57);
      this.AsignrValorRut(ncrutm+ndrutm);     
    }
  }

  analizar_rut_antiguoOCR(barcode: string) {
    //dato1 = "IDCHL12779409K7S37;;;;;;;;;;;;7501218M1501216CHLA002937292;6ANDRADE;MIRANDA;;JUAN;MANUEL;;";
    var Rut;
    var rutvalidador;
    var validarvacio = barcode.substring(5, 14);

    //Para rut mayor a 10 mill
    var crutm = barcode.substring(5, 13);
    var drutm = barcode.substring(13, 14);
    rutvalidador = crutm + "-" + drutm;
    var rutvalido = this.ValidatorsService.validarRut(crutm+drutm);
    if (rutvalido == false) {
      crutm = barcode.substring(5, 12);
      drutm = barcode.substring(12, 13);
      this.AsignrValorRut(crutm+drutm);    
    }
    else{
      this.AsignrValorRut(crutm+drutm);     
    }
  }

  analizar_cedula_nuevaQR(barcode: string) {
    //dato1 = "inchl1088752229s50;;;;;;;;;;;;6505172m2505174chl9251277;0;;2herlinger;kaufmann;;ilan;;;;;;;";
    //string validacedula, rutvalidador;
    var Rut;
    var rutvalidador;
    var ncrutm = barcode.substring(52,60);
    var verificarguion = barcode.substring(60, 61);
    console.log(verificarguion);
    
    var ndrutm = barcode.substring(61, 62);
    if (verificarguion == "'" ) {
      //Para rut mayor a 10 mill
      Rut = ncrutm + "-" + ndrutm;
      this.AsignrValorRut(ncrutm+ndrutm);         
    }
    else {
      //Para rut menor a 10 mill
      ncrutm = barcode.substring(52, 59);
      verificarguion = barcode.substring(59, 60);
      ndrutm = barcode.substring(60, 61);
      this.AsignrValorRut(ncrutm+ndrutm);     
    }
  }

  analizar_rut_antiguoQR(barcode: string) {
    //dato1 = "IDCHL12779409K7S37;;;;;;;;;;;;7501218M1501216CHLA002937292;6ANDRADE;MIRANDA;;JUAN;MANUEL;;";
    var Rut;
    var rutvalidador;
    var validarvacio = barcode.substring(0, 7);

    //Para rut mayor a 10 mill
    var crutm = barcode.substring(0, 7);
    var drutm = barcode.substring(7, 1);
    rutvalidador = crutm + "-" + drutm;
    var rutvalido = this.ValidatorsService.validarRut(crutm+drutm);
    if (rutvalido == false) {
      crutm = barcode.substring(0, 8);
      drutm = barcode.substring(8, 9);
      this.AsignrValorRut(crutm+drutm);      
    }
    else{
      this.AsignrValorRut(crutm+drutm);     
    }
  }

  volver() {
    this.Router.navigate(['']);
  }

  keyboardSubscription(): void {
    var esto = this;
    this.subscription.add(this.emitTecladoService.dataInput.subscribe(data => {
      if (data.input === 'inputUser') {
        this.user.setValue(data.texto.value);
        var rut = this.user.value + "";
        rut = rut.replace(".", "").replace(".", "").replace(".", "").replace("-", "");
        if (esto.ValidatorsService.validarRut(rut) == false) {
          this.user.setValue(rut);
          esto.rutvalido = false;
        }
        else {
          esto.rutvalido = true;
          esto.user.setValue(esto.ValidatorsService.formaterRut(this.user.value));
        }
      }
    }));

  }

  desplegarRut(model: string): void {
    const options = { class: 'mt-5 p-5 modal-ingresoRut', initialState: {} };

    this.modalTecladoService.openModal(KeyboardComponent, options);

    this.emitTecladoService.enviarModel({
      input: model,
      teclado: 'rut',
      texto: this.user.value,
      inputType: 'text',
      max: 14,
    });
  }

 AsignrValorRut(rut){
  var rutvalido = this.ValidatorsService.validarRut(rut);
  if (rutvalido) {
    this.rutvalido = true;
    this.user.setValue(this.ValidatorsService.formaterRut(rut));
    this.SweetAlertService.swalSuccess("Éxito","Rut leido Correctamente");
  }
  else{
      this.SweetAlertService.swalErrorgeneral("Error QR","Ha ocurrido un error al realizar la lectura del QR");
  }

 }


}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Lockers } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdmiUsuModalComponent } from '../admi-usu-ofi/admi-usu-modal/admi-usu-modal.component';
import { LockersModalComponent } from './lockers-modal/lockers-modal.component';

@Component({
  selector: 'app-admi-lockers',
  templateUrl: './admi-lockers.component.html',
  styleUrls: ['./admi-lockers.component.scss']
})
export class AdmiLockersComponent implements OnInit {

  lockers: Lockers[]
  lockersV2: [] = [];
  myGroup: FormGroup;
  datosInterface: Lockers;
  selectedProducts: Lockers[];
  modalRef: BsModalRef;

  loading;


  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos() {
    this.myGroup = new FormGroup({
      idLock: new FormControl(),
      idOfi: new FormControl(),
      numPuertas: new FormControl(),
      habili: new FormControl()
    });
  }

  async traerdatos(datos) {
    console.log(datos);
    var reqLocker = {
      "acc": "U",
      "idL": datos.idLocker,
      "idO": datos.idOficina,
      "nPuertas": parseInt(datos.iNumPuertas),
      "hab": datos.bHab == "true" || datos.bHab == true ? true:false,
      "sNomLocker": datos.sNomLocker,
      "sDireccion": datos.sDireccion,
      "idComuna": datos.idComuna,
      "sLatitud": datos.sLatitud,
      "sLongitud": datos.sLongitud,
      "iLuhi": datos.iLuhi,
      "iLumi": datos.iLumi,
      "iLuhf": datos.iLuhf,
      "iLumf": datos.iLumf,
      "iMahi": datos.iMahi,
      "iMami": datos.iMami,
      "iMahf": datos.iMahf,
      "iMamf": datos.iMamf,
      "iMihi": datos.iMihi,
      "iMimi": datos.iMimi,
      "iMihf": datos.iMihf,
      "iMimf": datos.iMimf,
      "iJuhi": datos.iJuhi,
      "iJumi": datos.iJumi,
      "iJuhf": datos.iJuhf,
      "iJumf": datos.iJumf,
      "iVihi": datos.iVihi,
      "iVimi": datos.iVimi,
      "iVihf": datos.iVihf,
      "iVimf": datos.iVimf,
      "iSahi": datos.iSahi,
      "iSami": datos.iSami,
      "iSahf": datos.iSahf,
      "iSamf": datos.iSamf,
      "iDohi": datos.iDohi,
      "iDomi": datos.iDomi,
      "iDohf": datos.iDohf,
      "iDomf": datos.iDomf
    }
    console.log(reqLocker);
    
    var resp = await this.PruebasService.ActualizarLockers(reqLocker);
    if (resp["status"]) {
      this.lockersV2 = resp['data'];
      console.log(resp['data']);
    }
    else {
    }
  }

  async agregarDatos(datos) {
    console.log(datos);
    var reqLocker = {
      "acc": "N",
      "idL": datos.idLocker,
      "idO": datos.idOficina,
      "nPuertas": parseInt(datos.iNumPuertas),
      "hab": datos.bHab == "true" || datos.bHab == true ? true:false,
      "sNomLocker": datos.sNomLocker,
      "sDireccion": datos.sDireccion,
      "idComuna": datos.idComuna,
      "sLatitud": datos.sLatitud,
      "sLongitud": datos.sLongitud,
      "iLuhi": datos.iLuhi,
      "iLumi": datos.iLumi,
      "iLuhf": datos.iLuhf,
      "iLumf": datos.iLumf,
      "iMahi": datos.iMahi,
      "iMami": datos.iMami,
      "iMahf": datos.iMahf,
      "iMamf": datos.iMamf,
      "iMihi": datos.iMihi,
      "iMimi": datos.iMimi,
      "iMihf": datos.iMihf,
      "iMimf": datos.iMimf,
      "iJuhi": datos.iJuhi,
      "iJumi": datos.iJumi,
      "iJuhf": datos.iJuhf,
      "iJumf": datos.iJumf,
      "iVihi": datos.iVihi,
      "iVimi": datos.iVimi,
      "iVihf": datos.iVihf,
      "iVimf": datos.iVimf,
      "iSahi": datos.iSahi,
      "iSami": datos.iSami,
      "iSahf": datos.iSahf,
      "iSamf": datos.iSamf,
      "iDohi": datos.iDohi,
      "iDomi": datos.iDomi,
      "iDohf": datos.iDohf,
      "iDomf": datos.iDomf
    }
    var resp = await this.PruebasService.ActualizarLockers(reqLocker);
    if (resp["status"]) {
      this.lockersV2 = resp['data'];
      console.log(resp['data']);

    }
    else {

    }
  }
  async deleteSelected(datos) {
    console.log(datos);
    var reqLocker = {
      "acc": "U",
      "idL": datos.idLocker,
      "idO": datos.idOficina,
      "nPuertas": datos.iNumPuertas,
      "hab": datos.bHab == "true" || datos.bHab == true ? false:true,
      "sNomLocker": parseInt(datos.sNomLocker),
      "sDireccion": datos.sDireccion,
      "idComuna": datos.idComuna,
      "sLatitud": datos.sLatitud,
      "sLongitud": datos.sLongitud,
      "iLuhi": datos.iLuhi,
      "iLumi": datos.iLumi,
      "iLuhf": datos.iLuhf,
      "iLumf": datos.iLumf,
      "iMahi": datos.iMahi,
      "iMami": datos.iMami,
      "iMahf": datos.iMahf,
      "iMamf": datos.iMamf,
      "iMihi": datos.iMihi,
      "iMimi": datos.iMimi,
      "iMihf": datos.iMihf,
      "iMimf": datos.iMimf,
      "iJuhi": datos.iJuhi,
      "iJumi": datos.iJumi,
      "iJuhf": datos.iJuhf,
      "iJumf": datos.iJumf,
      "iVihi": datos.iVihi,
      "iVimi": datos.iVimi,
      "iVihf": datos.iVihf,
      "iVimf": datos.iVimf,
      "iSahi": datos.iSahi,
      "iSami": datos.iSami,
      "iSahf": datos.iSahf,
      "iSamf": datos.iSamf,
      "iDohi": datos.iDohi,
      "iDomi": datos.iDomi,
      "iDohf": datos.iDohf,
      "iDomf": datos.iDomf
    }
    var resp = await this.PruebasService.ActualizarLockers(reqLocker);
    if (resp["status"]) {
      this.lockersV2 = resp['data'];
      console.log(resp['data']);
    }
    else {
    }
  }
  async llenarTabla() {
    let response = await this.PruebasService.traerLockers();
    if (response['status']) {
      this.lockersV2 = response['dataLoc'];
      console.log("Lockers:", this.lockersV2);
    }
  }
  Seleccionar() {
    const opcion = { class: 'w-50', initialState: { regiones: this.lockersV2 } }
    this.modalRef = this.modalService.openModal(LockersModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if (result) {
        this.llenarTabla()
      }
    });
  }

}

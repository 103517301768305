import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-menu-opciones',
  templateUrl: './menu-opciones.component.html',
  styleUrls: ['./menu-opciones.component.scss']
})
export class MenuOpcionesComponent implements OnInit {
  elementosUsados: [] = [];
  elementosDisponibles: [] = [];
  elementosdanados: [] = [];
  elementosdanadoshoy: [] = [];

  constructor(
    private router: Router,
    private PruebasService: PruebasService,
    private SweetAlertService: SweetAlertService) { }

  async ngOnInit(): Promise<void> {
    await this.traerDatosElementos();
  }

  async traerDatosElementos()
  {
    this.SweetAlertService.swalLoading();
    let response = await this.PruebasService.GetDatoadeldia();
      if (response["status"]) {
        this.elementosUsados = response["elementoUsado"];
        this.elementosDisponibles = response["disponibles"];
        this.elementosdanados = response["danados"];
        this.elementosdanadoshoy = response["danadoshoy"]
        console.log("USADOS: ", this.elementosUsados);
        console.log("DISPONIBLES: ", this.elementosDisponibles);
      }
      else{
        this.SweetAlertService.swalError("no se puede crear elementos en este momento, vuelva a intentar");
      }
      this.SweetAlertService.swalClose();
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { City, Oficinas } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import {DropdownModule} from 'primeng/dropdown';
import * as moment from 'moment';

@Component({
  selector: 'app-oficinas-modal',
  templateUrl: './oficinas-modal.component.html',
  styleUrls: ['./oficinas-modal.component.scss']
})
export class OficinasModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  //@Input() regiones;
  cities: City[];
  selectedCity: City;
  moment = moment;
  ListaOficinas: [] = [];

  constructor(
    private dropDownModule: DropdownModule,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private SweetAlertService: SweetAlertService,
    private router: Router) {  }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  async iniciarDato() {
    this.registerForm = this.formBuilder.group({
      idOf: ['', Validators.required],
      sOf: ['', Validators.required],
      sCorreo: ['', [ Validators.required, Validators.pattern(/^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/)]],
      hab: [null, Validators.required]
    });
    this.SweetAlertService.swalLoading();
    var oficinas = await this.PruebasService.TraerRoles();
    if (oficinas["status"] == true) {
      this.ListaOficinas = oficinas["data"];
    }
    else{
      this.SweetAlertService.swalError("no se pueden crear elementos en este momento, vuelva a intentar");
    }
    
    this.SweetAlertService.swalClose();
  }

  obtenerdatos() {
    console.log("que trae: ", this.registerForm.value);
    
    this.datosInterface = {

      "idO": this.registerForm.value.idOf,
      "correo": this.registerForm.value.sCorreo,
      "sOficina": this.registerForm.value.sOf,
      "estado": this.registerForm.value.hab

    }
    console.log('Datos Interfaz: ', this.datosInterface);

  }
  
  async agregarOficina() {

    this.submitted = true;
    if(this.registerForm.invalid){return;}

    await this.obtenerdatos();
    console.log(this.datosInterface);
    this.SweetAlertService.swalLoading();
    let datosRetornados = await this.PruebasService.AgregarOficinasMethanext(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status === true) {
      await this.SweetAlertService.swalSuccess("Éxito","¡Oficina creada!");
      this.SweetAlertService.swalClose();
      this.cerrarModal();
    }
    else {
      await this.SweetAlertService.swalError("¡No se pudo crear la oficina, intente nuevamente!");
    }
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { Zonas } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-zona-modal',
  templateUrl: './zona-modal.component.html',
  styleUrls: ['./zona-modal.component.scss']
})
export class ZonaModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface: Zonas;
  onClose: Subject<boolean>;
  //@Input() zonas;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
    //console.log('Array Zonas: ', this.zonas);
  }



  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  iniciarDato() {
    this.registerForm = new FormGroup({
      idZon: new FormControl(),
      nomZon: new FormControl()
    });
  }

  obtenerdatos() {
    console.log(this.registerForm.value);
    this.datosInterface = {
      acc: 'N',
      idZ: this.registerForm.value.idZon,
      zona: this.registerForm.value.nomZon
    }
    console.log('Datos Interfaz: ', this.datosInterface);

  }
    async agregarZona() {

    this.obtenerdatos()
    let datosRetornados = await this.PruebasService.crudZonas(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito","¡Zona creada!");    
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se puedo crear el pedido, intente nuevamente 🙂!");
    }
  }
  

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { UsuariosOfi } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdmiUsuModalComponent } from '../admi-usu-ofi/admi-usu-modal/admi-usu-modal.component';
import { FormatoRutPipe } from '../../core/pipes/formato-rut.pipe'

@Component({
  selector: 'app-admi-usu-ofi',
  templateUrl: './admi-usu-ofi.component.html',
  styleUrls: ['./admi-usu-ofi.component.scss']
})
export class AdmiUsuOfiComponent implements OnInit {

  usuariosOfi: UsuariosOfi[]
  usuariosOfi2: [] = [];
  myGroup: FormGroup;
  datosInterface: UsuariosOfi;
  selectedProducts: UsuariosOfi[];
  modalRef: BsModalRef;

  loading;


  constructor(
    private PruebasService: PruebasService,
    private modalService: ModalService,
    private Router: Router,
    private SweetAlertService: SweetAlertService,
    private FormatoRutPipe: FormatoRutPipe) { }

  async ngOnInit() {
    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos() {
    this.myGroup = new FormGroup({
      idOfi: new FormControl(),
      rutUsu: new FormControl(),
      pass: new FormControl()
    });
  }

  async traerdatos(datos) {
    console.log(datos);
    var reqUsuario = {
      "acc": "U",
      "idO": datos.idOficina,
      "dniUsu": datos.sDniUsu,
      "password": datos.password2 == undefined ? "": datos.password2
    }
    console.log(reqUsuario);
    
    var resp = await this.PruebasService.ActualizarUsuariosOfi(reqUsuario);
    console.log("RESPUESTA",resp);
    if (resp["status"]) {
      this.usuariosOfi2 = resp['data'];
      this.SweetAlertService.swalSuccess("Éxito","Se ha modificado el usuario");
      console.log(resp['data']);
    }
    else {
      this.SweetAlertService.swalError("Error, No se pudieron realizar los cambios. Intente nuevamente");
    }
  }

  async agregarDatos(datos) {
    console.log(datos);
    var reqUsuario = {
      "acc": "N",
      "idO": datos.idOficina,
      "dniUsu": datos.sDniUsu,
      "password": datos.usOPass
    }
    var resp = await this.PruebasService.ActualizarUsuariosOfi(reqUsuario);
    if (resp["status"]) {
      this.usuariosOfi2 = resp['data'];
      console.log(resp['data']);

    }
    else {

    }
  }

  async deleteSelected(datos) {
    console.log(datos);
    var reqUsuario = {
      "acc": "U",
      "idO": datos.idOficina,
      "dniUsu": !datos.sDniUsu,
      "password": datos.usOPass
    }
    var resp = await this.PruebasService.ActualizarUsuariosOfi(reqUsuario);
    if (resp["status"]) {
      this.usuariosOfi2 = resp['data'];
      console.log(resp['data']);
    }
    else {
    }
  }

  async llenarTabla() {
    let response = await this.PruebasService.traerUsuariosOfi();
    if (response['status']) {
      this.usuariosOfi2 = response['dataUsO'];
      console.log("Usuarios:", this.usuariosOfi2);
    }
  }
  
  Seleccionar() {
    const opcion = { class: 'w-50', initialState: { regiones: this.usuariosOfi2 } }
    this.modalRef = this.modalService.openModal(AdmiUsuModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if (result) {
        this.llenarTabla()
      }
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from './modules/shared/shared.module';
import { CustomerService } from './customerservice';

import { TarjetaLockerService } from './core/services/http/tarjeta-locker.service';
import { ApiLockerService } from './core/services/http/api-locker.service';

// PrimeNg

import {DropdownModule} from 'primeng/dropdown';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {MenuItem} from 'primeng/api';
import { Ng2Rut } from 'ng2-rut';



import { AppComponent } from './app.component';
import { KeyboardComponent } from './custom/keyboard/keyboard.component';
import { HomeComponent } from './modules/home/home.component';
import { AdminComponent } from './modules/admin/pages/admin.component';
import { LoginComponent } from './modules/admin/components/login/login.component';
import { MantenedorComponent } from './modules/admin/components/mantenedor/mantenedor.component';
import { EscanearQRComponent } from './modules/escanear-qr/escanear-qr.component';
import { CienteComponent } from './modules/ciente/ciente.component';
import { UsuarioComponent } from './modules/usuario/usuario.component';
import { SelectTipoUsuComponent } from './modules/select-tipo-usu/select-tipo-usu.component';
import { EscanRutComponent } from './modules/usuario/escan-rut/escan-rut.component';
import { SeleccionEmpresaComponent } from './modules/usuario/seleccion-empresa/seleccion-empresa.component';
import { LoginUsuarioComponent } from './modules/login-usuario/login-usuario.component';
import { TablaUsuariosComponent } from './modules/tabla-usuarios/tabla-usuarios.component';

import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import { TablaPedidosComponent } from './modules/tabla-pedidos/tabla-pedidos.component';
import { AdmiZonasComponent } from './modules/admi-zonas/admi-zonas.component';
import { AdmiRegionesComponent } from './modules/admi-regiones/admi-regiones.component';
import { AdmiComunasComponent } from './modules/admi-comunas/admi-comunas.component';
import { AdmiOficinasComponent } from './modules/admi-oficinas/admi-oficinas.component';
import { AdmiClientesComponent } from './modules/admi-clientes/admi-clientes.component';
import { AdmiLockersComponent } from './modules/admi-lockers/admi-lockers.component';
import { AdmiCasillerosComponent } from './modules/admi-casilleros/admi-casilleros.component';
import { AdmiNuevoPedidoComponent } from './modules/admi-nuevo-pedido/admi-nuevo-pedido.component';
import { VistaGeneralComponent } from './modules/vista-general/vista-general.component';
import { VistaModalComponent } from './modules/vistaModal/vista-modal/vista-modal.component';
import { NuevoPedidoComponent } from './modules/nuevo-pedido/nuevo-pedido.component';
import { RetirarPedidoComponent } from './modules/retirar-pedido/retirar-pedido.component';
import { MenuOpcionesComponent } from './modules/menu-opciones/menu-opciones.component';
import { TablaModalComponent } from './modules/tabla-pedidos/tabla-modal/tabla-modal.component';
import { AdmiUsuOfiComponent } from './modules/admi-usu-ofi/admi-usu-ofi.component';
import { ZonaModalComponent } from './modules/admi-zonas/zona-modal/zona-modal.component';
import { RegionesModalComponent } from './modules/admi-regiones/regiones-modal/regiones-modal.component';
import { ComunasModalComponent } from './modules/admi-comunas/comunas-modal/comunas-modal.component';
import { OficinasModalComponent } from './modules/admi-oficinas/oficinas-modal/oficinas-modal.component';
import { ClientesModalComponent } from './modules/admi-clientes/clientes-modal/clientes-modal.component';
import { UsuariosModalComponent } from './modules/tabla-usuarios/usuarios-modal/usuarios-modal.component';
import { AdmiUsuModalComponent } from './modules/admi-usu-ofi/admi-usu-modal/admi-usu-modal.component';
import { LockersModalComponent } from './modules/admi-lockers/lockers-modal/lockers-modal.component';
import { CasillerosModalComponent } from './modules/admi-casilleros/casilleros-modal/casilleros-modal.component';
import { AdmiBodegaComponent } from './modules/admi-bodega/admi-bodega.component';
import { BodegasModalComponent } from './modules/admi-bodega/bodegas-modal/bodegas-modal.component';
import { ReporteLogComponent } from '../app/modules/Reportería/reporte-log/reporte-log.component';
import { ReporteCierreComponent } from '../app/modules/Reportería/reporte-cierre/reporte-cierre.component';
import { HeaderTableComponent } from '../app/modules/Reportería/header-table/header-table.component';
import { DatatableComponent } from '../app/modules/Reportería/datatable/datatable.component';
import { DataTablesModule } from 'angular-datatables';
import { PesoChilenoPipe } from '../app/core/pipes/peso-chileno.pipe'
import { DatePipe } from '@angular/common';
import { ReenviarCorreoComponent } from './modules/reenviar-correo/reenviar-correo.component';
import { FormatoRutPipe } from './core/pipes/formato-rut.pipe';
import { TipoElementoComponent } from './modules/elementos/tipo-elemento/tipo-elemento.component';
import { ElementoComponent } from './modules/elementos/elemento/elemento.component';
import { TipoEModalComponent } from './modules/elementos/tipo-elemento/tipo-emodal/tipo-emodal.component';
import { ElementoModalComponent } from './modules/elementos/elemento/elemento-modal/elemento-modal.component';
import { MasivaElementosComponent } from './modules/carga-masiva/masiva-elementos/masiva-elementos.component';
import { MasivaUsuariosComponent } from './modules/carga-masiva/masiva-usuarios/masiva-usuarios.component';
import { ElementoUploadComponent } from './modules/carga-masiva/masiva-elementos/Upload/elemento-upload/elemento-upload.component';
import { UploadService } from './core/services/http/upload.service';





@NgModule({
  declarations: [
    AppComponent,
    PesoChilenoPipe,
    FormatoRutPipe,
    HeaderTableComponent,
    ReporteCierreComponent,
    DatatableComponent,
    HomeComponent,
    KeyboardComponent,
    AdminComponent,
    LoginComponent,
    MantenedorComponent,
    EscanearQRComponent,
    CienteComponent,
    UsuarioComponent,
    SelectTipoUsuComponent,
    EscanRutComponent,
    SeleccionEmpresaComponent,
    LoginUsuarioComponent,
    TablaUsuariosComponent,
    TablaPedidosComponent,
    AdmiZonasComponent,
    AdmiRegionesComponent,
    AdmiComunasComponent,
    AdmiOficinasComponent,
    AdmiClientesComponent,
    AdmiLockersComponent,
    AdmiCasillerosComponent,
    AdmiNuevoPedidoComponent,
    VistaGeneralComponent,
    VistaModalComponent,
    NuevoPedidoComponent,
    RetirarPedidoComponent,
    MenuOpcionesComponent,
    TablaModalComponent,
    AdmiUsuOfiComponent,
    ZonaModalComponent,
    RegionesModalComponent,
    ComunasModalComponent,
    OficinasModalComponent,
    ClientesModalComponent,
    UsuariosModalComponent,
    AdmiUsuModalComponent,
    LockersModalComponent,
    CasillerosModalComponent,
    AdmiBodegaComponent,
    BodegasModalComponent,
    ReporteLogComponent,
    ReenviarCorreoComponent,
    TipoElementoComponent,
    ElementoComponent,
    TipoEModalComponent,
    ElementoModalComponent,
    MasivaElementosComponent,
    MasivaUsuariosComponent,
    ElementoUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    UserIdleModule.forRoot({ idle: 240, timeout: 60 }),
    TableModule,
    ReactiveFormsModule,
    AccordionModule,
    DropdownModule,
    FormsModule,
    ModalModule.forRoot(),
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule,
    InputTextModule,
    ProgressBarModule,
    ToastModule,
    SliderModule,
    DataTablesModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    Ng2Rut
  ],
  exports: [
    PesoChilenoPipe,
  ],
  entryComponents: [KeyboardComponent],
  providers: [TarjetaLockerService, ApiLockerService, CustomerService, DatePipe, PesoChilenoPipe, FormatoRutPipe, UploadService],
  bootstrap: [AppComponent]
})
export class AppModule { }

<!-- main app container -->
<div class="card m-3">
    <h5 class="card-header" class="text-secondary">Datos de Nueva Zona</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Zona</label>
                <input type="number" id="iZona" formControlName="idZon" class="form-control" name="iZona">
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Zona</label>
                <input type="text" id="nZona" formControlName="nomZon" class="form-control" name="nZona">
            </div>
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarZona()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Comunas } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { RegionesModalComponent } from '../admi-regiones/regiones-modal/regiones-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComunasModalComponent } from './comunas-modal/comunas-modal.component';

@Component({
  selector: 'app-admi-comunas',
  templateUrl: './admi-comunas.component.html',
  styleUrls: ['./admi-comunas.component.scss']
})
export class AdmiComunasComponent implements OnInit {

  comunas: Comunas[]
  comunasV2: [] = [];
  myGroup: FormGroup;
  datosInterface: Comunas;
  selectedProducts: Comunas[];
  modalRef: BsModalRef;

  loading;


  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos()
{
  this.myGroup = new FormGroup({
    idCom: new FormControl(),
    idReg: new FormControl(),
    sComuna: new FormControl()
   }); 
}

async traerdatos(datos) {
  console.log(datos);
  var reqComuna = {
    "acc": "U",
    "idC": datos.idComuna,
    "idR": datos.idRegion,
    "comuna": datos.sComuna
  }
  var resp = await this.PruebasService.ActualizarComuna(reqComuna);
  if (resp["status"]) {
    this.comunasV2 = resp['data'];
    console.log(resp['data']);

  }
  else {

  }
}

async agregarDatos(datos) {
  console.log(datos);
  var reqComuna = {
    "acc": "N",
    "idC": datos.idComuna,
    "idR": datos.idRegion,
    "comuna": datos.sComuna,
  }
  var resp = await this.PruebasService.ActualizarComuna(reqComuna);
  if (resp["status"]) {
    this.comunasV2 = resp['data'];
    console.log(resp['data']);

  }
  else {

  }
}

async llenarTabla()
{
  let response = await this.PruebasService.traerComuna();
  if (response['status']) {
    this.comunasV2 = response['dataCom'];
    console.log("Comunas:", this.comunasV2);
  }
}

Seleccionar()
{
  const opcion = {class: 'w-50', initialState: {regiones: this.comunasV2}}
  this.modalRef = this.modalService.openModal(ComunasModalComponent, opcion);
  this.modalRef.content.onClose.subscribe((result) => {
    console.log('result: ', result);
    if(result){
      this.llenarTabla()
    }

  });
}




}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { cargarPedido } from 'src/app/customer';
import { ModalService } from '../../../core/services/modals/modal.service';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import {SweetAlertService} from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { timestamp } from 'rxjs/operators';

@Component({
  selector: 'app-tabla-modal',
  templateUrl: './tabla-modal.component.html',
  styleUrls: ['./tabla-modal.component.scss']
})
export class TablaModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface : cargarPedido;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
  }

  get f() { return this.registerForm.controls; }

  cerrarModal(){
    this.modalService.closeModal();
  }

  iniciarDato()
  {
    this.registerForm = new FormGroup({
      passWrd : new FormControl(),
      idpedido: new FormControl()
    });
  }

  obtenerdatos(){
    console.log(this.registerForm.value);
    this.datosInterface = {
      idPed: parseInt(localStorage.getItem('idpedido')),
      clave: parseInt(this.registerForm.value.passWrd)
    }
    console.log('Datos Interfaz: ', this.datosInterface);
  }


  async cargarPedido()
  {
    this.obtenerdatos();
    console.log('FORMULARIO: ', this.datosInterface);
    let datosRetornados = await this.PruebasService.cargarPedido(this.datosInterface)
    console.log('RESPONSE: ',datosRetornados);
    if(datosRetornados.status)
    {
      await this.SweetAlertService.swalSuccess("Éxito","¡Carga completada!");
      window.location.reload();
      this.cerrarModal();
    }
    else if(datosRetornados.code == 401)
    {
      await this.SweetAlertService.swalError("¡Error, La oficina no se encuentra habilitada!");
    }
    else if(datosRetornados.code == 402)
    {
      await this.SweetAlertService.swalError("¡Error, ID LOCKER no se encuentra habilitado!");
    }
    else if(datosRetornados.code == 403)
    {
      await this.SweetAlertService.swalError("¡Error, ID CASILLERO no se encuentra habilitado!");
    }
    else if(datosRetornados.code == 404)
    {
      await this.SweetAlertService.swalError("¡Error, ID PEDIDO no existe!");
    }
    else if(datosRetornados.code == 405)
    {
      await this.SweetAlertService.swalError("¡Clave incorrecta, intente nuevamente!");
    }

    //API PUERTAS
    // let datosRetornados2 = await this.PruebasService.cargarPedidoCasillerosAbrir()
    // console.log('RESPONSE: ',datosRetornados);
    // if(datosRetornados2.status == true)
    // {
    //   this.SweetAlertService.swalSuccess("¡Carga completada 📬!");
    //   window.location.reload();
    //   this.cerrarModal();
    // }
    // else
    // {
    //   this.SweetAlertService.swalError("¡Clave incorrecta, intente nuevamente 🙂!");
    // }
    
    
  }



}

<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<link href="//netdna.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.2.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-2.1.3.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <button (click)="volver()">Volver</button>
    <form class="form-horizontal" role="form" [formGroup]="myGroup">
        <h2>Nuevo Pedido</h2>
        <div class="form-group">
            <label for="idLocker" class="col-sm-3 control-label">*ID Locker</label>
            <div class="col-sm-9">
                <input type="number" formControlName="idLocker" id="idLocker" placeholder="ID Locker"
                    class="form-control" autofocus>
            </div>
        </div>
        <div class="form-group">
            <label for="idO" class="col-sm-3 control-label">*ID Oficina</label>
            <div class="col-sm-9">
                <input type="number" formControlName="idOfi" id="idO" placeholder="ID Oficina" class="form-control"
                    autofocus>
            </div>
        </div>
        <div class="form-group">
            <label for="idCa" class="col-sm-3 control-label">*ID Casillero</label>
            <div class="col-sm-9">
                <input type="number" formControlName="idCas" id="idCa" placeholder="ID Casillero" class="form-control"
                    name="idCa">
            </div>
        </div>
        <div class="form-group">
            <label for="dniCli" class="col-sm-3 control-label">*Rut Cliente</label>
            <div class="col-sm-9">
                <input type="text" formControlName="rutCli" id="dniCli" placeholder="12345678-9" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <label for="dniUsu" class="col-sm-3 control-label">*Rut Usuario</label>
            <div class="col-sm-9">
                <input type="text" formControlName="rutUsu" id="dniUsu" placeholder="12345678-9" class="form-control">
            </div>
        </div>

        <div class="form-group">
            <label for="email" class="col-sm-3 control-label">*Email</label>
            <div class="col-sm-9">
                <input type="email" formControlName="email" id="correo" placeholder="ejemplo@ejemplo.com"
                    class="form-control">
            </div>
        </div>

        <div class="form-group">
            <div class="col-sm-9 col-sm-offset-3">
                <span class="help-block">*Campos obligatorios</span>
            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block" (click)="cargarDatos()" onClick="this.disabled=true">Registrar</button>
    </form> <!-- /form -->
</div> <!-- ./container -->
import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { TipoElemento } from '../../../customer';
import { ModalService } from '../../../core/services/modals/modal.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { RegionesModalComponent } from '../../admi-regiones/regiones-modal/regiones-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TipoEModalComponent } from './tipo-emodal/tipo-emodal.component';
import { FormatoRutPipe } from '../../../core/pipes/formato-rut.pipe';
//exportar a excel
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//exportar a pdf
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-tipo-elemento',
  templateUrl: './tipo-elemento.component.html',
  styleUrls: ['./tipo-elemento.component.scss'],
})
export class TipoElementoComponent implements OnInit {
  TipoElementos: TipoElemento[];
  TipoElementV2: TipoElemento[] = [];
  myGroup: FormGroup;
  datosInterface: TipoElemento;
  selectedProducts: TipoElemento[];
  modalRef: BsModalRef;
  // exportación
  columns: any[] = [];
  exportColumns: any[];
  cols: any[];
  fileName = 'TipoElemento.xlsx';

  loading;
  sesionrol
  filteredValues: any;

  constructor(
    private PruebasService: PruebasService,
    private modalService: ModalService,
    private Router: Router,
    private SweetAlertService: SweetAlertService,
    private FormatoRutPipe: FormatoRutPipe
  ) {}

  async ngOnInit() {
    this.sesionrol = localStorage.getItem('rolsesion')
    this.iniciarDatos();
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos() {
    this.myGroup = new FormGroup({
      IdtipoElemento: new FormControl(),
      nombre: new FormControl(),
    });
  }

  async EditarDatos(datos: TipoElemento) {
    console.log(datos);
    var reqCliente = {
      id: datos.idTiposElemento,
      descripcion: datos.descripcion,
    };
    // this.exportColumns = this.cols.map((col) => ({
    //   title: col.header,
    //   dataKey: col.field,
    // }));
    this.SweetAlertService.swalLoading();
    var resp = await this.PruebasService.ActualizarTipoElemntos(reqCliente);
    if (resp['status']) {
      await this.llenarTabla();
      this.SweetAlertService.swalSuccess(
        'Éxito',
        'Se ha modificado tipo de elemento'
      );
    } else {
      this.SweetAlertService.swalError(
        'Error, No se pudieron realizar los cambios. Intente nuevamente'
      );
    }
  }

  async EliminarDatos(datos: TipoElemento) {
    var confirma = await this.SweetAlertService.swalEliminar(
      '¿Desea Eliminar este tipo de elemento?',
      'Solamente se podra elimniar el tipo de elemento si, no posee un elemnto asociado'
    );
    if (confirma == true) {
      console.log(datos);
      var reqCliente = datos.idTiposElemento;
      this.SweetAlertService.swalLoading();
      var resp = await this.PruebasService.EliminarTipoElemntos(reqCliente);
      if (resp['status']) {
        await this.llenarTabla();
        this.SweetAlertService.swalSuccess(
          'Éxito',
          'Se ha eliminado tipo de elemento'
        );
      } else {
        this.SweetAlertService.swalError(
          'Error, recuerde que no puede tener ningun elmento asociado'
        );
      }
    }
  }

  async agregarDatos(datos) {
    console.log(datos);
    var reqCliente = {
      acc: 'N',
      dniCli: datos.sDniCli,
      nom: datos.sNombre,
      fono: datos.sTelefono,
      mail: datos.sCorreo,
    };
    var resp = await this.PruebasService.ActualizarCliente(reqCliente);
    if (resp['status']) {
      this.TipoElementV2 = resp['data'];
      console.log(resp['data']);
    } else {
    }
  }

  async llenarTabla() {
    this.SweetAlertService.swalLoading();
    let response = await this.PruebasService.TraerTipoElemento();
    if (response['status']) {
      this.TipoElementV2 = response['data'];
      this.filteredValues = this.TipoElementV2;
      console.log('Tipos de Elementos:', this.TipoElementV2);
    }
    this.SweetAlertService.swalClose();
  }

  Seleccionar() {
    const opcion = {
      class: 'w-50',
      initialState: { regiones: this.TipoElementV2 },
    };
    this.modalRef = this.modalService.openModal(TipoEModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if (result) {
        this.llenarTabla();
      }
    });
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
  }

  //EXPORTAR A EXCEL (SE FILTRAN LOS DATOS A IMPRIMIR)
  exportExcel() {
    var datos = [];
    var toExport = [];
    if (this.filteredValues) {
      datos = this.filteredValues;
      for (let element of datos) {
        var obj = {
          ID: element.idTiposElemento,
          Nombre: element.descripcion,
        };
        toExport.push(obj);
      }
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(toExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'TipoElementos');
      });
    } else {
      datos = this.TipoElementV2;
      for (let element of datos) {
        var obj = {
          ID: element.idTiposElemento,
          Nombre: element.descripcion,
        };
        toExport.push(obj);
      }
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(toExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'TipoElementos');
      });
    }
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  //EXPORTAR A PDF
  exportPdf() {
    var datos = [];
    var toExport = [];
    datos = this.filteredValues;
    for (let element of datos) {
      var obj = {
        ID: element.idTiposElemento,
        Nombre: element.descripcion,
      };
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text('Tipos de Elementos', 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport });
    doc.save('TipoElemento.pdf');
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { AdminComponent } from './modules/admin/pages/admin.component';
import { EscanearQRComponent } from './modules/escanear-qr/escanear-qr.component';
import { CienteComponent } from './modules/ciente/ciente.component';
import { UsuarioComponent } from './modules/usuario/usuario.component';
import { LoginUsuarioComponent} from './modules/login-usuario/login-usuario.component';
import { TablaUsuariosComponent} from './modules/tabla-usuarios/tabla-usuarios.component';
import { AdmiUsuOfiComponent } from './modules/admi-usu-ofi/admi-usu-ofi.component';

import { SelectTipoUsuComponent } from './modules/select-tipo-usu/select-tipo-usu.component';
import { TablaPedidosComponent } from './modules/tabla-pedidos/tabla-pedidos.component';
import { AdmiZonasComponent } from './modules/admi-zonas/admi-zonas.component';
import { VistaGeneralComponent } from './modules/vista-general/vista-general.component';
import { AdmiRegionesComponent } from './modules/admi-regiones/admi-regiones.component';
import { AdmiComunasComponent } from './modules/admi-comunas/admi-comunas.component';
import { AdmiClientesComponent } from './modules/admi-clientes/admi-clientes.component';
import { AdmiLockersComponent } from './modules/admi-lockers/admi-lockers.component';
import { AdmiCasillerosComponent } from './modules/admi-casilleros/admi-casilleros.component';
import { AdmiOficinasComponent } from './modules/admi-oficinas/admi-oficinas.component';
import {VistaModalComponent} from './modules/vistaModal/vista-modal/vista-modal.component';
import {NuevoPedidoComponent} from '../../src/app/modules/nuevo-pedido/nuevo-pedido.component';
import {MenuOpcionesComponent} from '../../src/app/modules/menu-opciones/menu-opciones.component';
import {RetirarPedidoComponent} from '../../src/app/modules/retirar-pedido/retirar-pedido.component';
import {AdmiBodegaComponent} from '../../src/app/modules/admi-bodega/admi-bodega.component';
import { combineAll } from 'rxjs/operators';
import { from } from 'rxjs';
import { ReporteCierreComponent } from '../app/modules/Reportería/reporte-cierre/reporte-cierre.component';
import { ReporteLogComponent } from '../app/modules/Reportería/reporte-log/reporte-log.component';
import { ReenviarCorreoComponent } from '../app/modules/reenviar-correo/reenviar-correo.component';
import {  TipoElementoComponent } from '../app/modules/elementos/tipo-elemento/tipo-elemento.component';
import {  ElementoComponent } from '../app/modules/elementos/elemento/elemento.component';
import { MasivaElementosComponent } from '../app/modules/carga-masiva/masiva-elementos/masiva-elementos.component';
import { MasivaUsuariosComponent } from '../app/modules/carga-masiva/masiva-usuarios/masiva-usuarios.component';


// import { LockerComponent } from './modules/shared/locker/locker/locker.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'EscanearQR', component: EscanearQRComponent },
  { path: 'reporte-log', component: ReporteLogComponent },
  { path: 'cierre', component: ReporteCierreComponent },
  { path: 'reenviar-correo', component: ReenviarCorreoComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'cliente', component: CienteComponent },
  { path: 'usuario', component: UsuarioComponent },
  { path: 'SelectTipo', component: SelectTipoUsuComponent },
  { path: 'LoginUser', component: LoginUsuarioComponent },
  { path: 'TablaUser', component: TablaUsuariosComponent},
  { path: 'TablaPedido', component: TablaPedidosComponent},
  { path: 'TablaZona', component: AdmiZonasComponent},
  { path: 'TablaRegiones', component: AdmiRegionesComponent},
  { path: 'TablaComunas', component: AdmiComunasComponent},
  { path: 'TablaClientes', component: AdmiClientesComponent},
  { path: 'TablaBodegas', component: AdmiBodegaComponent},
  { path: 'TablaUsuariosOfi', component: AdmiUsuOfiComponent},
  { path: 'TablaLockers', component: AdmiLockersComponent},
  { path: 'TablaCasilleros', component: AdmiCasillerosComponent},
  { path: 'TablaOficinas', component: AdmiOficinasComponent},
  { path: 'VistaGeneral', component: VistaGeneralComponent},
  { path: 'VistaModal', component: VistaModalComponent},
  { path: 'NuevoPedido', component: NuevoPedidoComponent},
  { path: 'RetirarPedido', component: RetirarPedidoComponent},
  { path: 'MenuOpciones', component: MenuOpcionesComponent},

  //Methanet
  { path: 'TipoElemento', component: TipoElementoComponent},
  { path: 'Elemento', component: ElementoComponent},
  { path: 'CargaMasivaElementos', component: MasivaElementosComponent},
  { path: 'CargaMasivaUsuarios', component: MasivaUsuariosComponent}
  
  // { path: 'Locker', component: LockerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

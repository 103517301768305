import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ReporteriaService } from 'src/app/core/services/http/reporteria.service';

@Component({
  selector: 'app-header-table',
  templateUrl: './header-table.component.html',
  styleUrls: ['./header-table.component.scss']
})
export class HeaderTableComponent implements OnInit {
  @Output() changeAction = new EventEmitter<any>();
  formulario!: FormGroup;
  oficinas: any[] = [];
  kioskos: any[] = [];
  maxDate: any;
  isSubmitted = false;
  path!: string;
  formuAEnviar: any;

  constructor(
    private formBuilder: FormBuilder,
    private sweetAlertService: SweetAlertService,
    private reporteriaService: ReporteriaService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.path = this.route.snapshot.routeConfig?.path || '';
    this.maxDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    this.initForm();
    this.getSucursales();
  }

  get f(): any { return this.formulario.controls; }

  initForm(): void {
    this.formulario = this.formBuilder.group({
      fechaInicio: [this.datePipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
      fechaFin: [this.datePipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required]
    });
    this.action();
  }

  async getSucursales(): Promise<void> {
    this.sweetAlertService.swalLoading();
    const response = await this.reporteriaService.ListarOficina();
    this.sweetAlertService.swalClose();

    if (response['status']) {
      const sucursales = response['data'];
      this.oficinas = sucursales.filter((value) => value.estado);
    }
  }

  async getKioskos(): Promise<void> {
    // if (this.f.idOficina.valid) {
    //   const response = await this.reporteriaService.ListarTotem(this.f.idOficina.value);

    //   if (response['status'] && response['data']) {
    //     const kioskos = response['data'];
    //     this.kioskos = kioskos.filter((value) => value.estado);
    //   }
    // }
  }

  action(): void {
    this.getKioskos();
    this.formulario.valid ? this.changeAction.emit(this.formulario.value) : this.isSubmitted = true;
    console.log("Formulario a enviar: ", this.formulario.value);
    
  }
}

import { Component, OnInit } from '@angular/core';
import { TarjetaLockerService } from '../../../../core/services/http/tarjeta-locker.service';
import { SweetAlertService } from '../../../../core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from '../../../../core/services/http/pruebas.service';

@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss']
})
export class LockerComponent implements OnInit {

  Locker;
  pedidos;
  casilleroHover;
  ventanaCasH = {};
  constructor(private TarjetaLockerService: TarjetaLockerService, private SweetAlertService: SweetAlertService, private PruebasService: PruebasService) { }

  ngOnInit(): void {
    // this.TarjetaLockerService.Conectar();
    this.CargarLocker();
    this.casilleroHover = false;
  }

  hovercas(index) {
   
    this.Locker.forEach(columnas => {
      columnas.Casilleros.forEach(casillero => {
        this.pedidos.forEach(estadocas => {
          if (casillero.id == estadocas.idCasillero  && casillero.id ==index && casillero.tama != "admin") {
            this.casilleroHover = true;
            console.log("MARCADO: ",this.pedidos[index-1]);
            this.ventanaCasH = estadocas;
          }
        });
      });

    });
    
  }

  leavecas(index) {
    this.casilleroHover = false;
    // console.log("MARCADO 2:", index);
    // console.log("MARCADO 3:",this.pedidos[index-1]);

  }

  async CargarLocker() {
    this.SweetAlertService.swalLoading();
    this.Locker = await this.TarjetaLockerService.DibujoCasillero();
    // console.log(this.Locker);

    var respuesta = await this.PruebasService.Listacasilleros(1);
    respuesta = respuesta["data"];
    // console.log(respuesta);
    this.pedidos = respuesta

    this.Locker.forEach(columnas => {
      //console.log("COLUMNA CASILLEROS: ",columnas.Casilleros);

      columnas.Casilleros.forEach(casillero => {
        this.pedidos.forEach(estadocas => {
          if (casillero.id == estadocas.idCasillero && estadocas.sEstado != "desocupado" && estadocas.sEstado != "desocupado") {
            casillero.ocupado = true;
            //console.log("ESTADOCAS: ", estadocas.sEstado);
            
          }
        });
      });

    });
    //console.log("estado casillero:", this.pedidos);
    // console.log("Locker:", this.Locker);
    //this.SweetAlertService.swalClose();
  }

  async Liberar()
  {
    var reqOficina = {
      "rutUsuario": this.ventanaCasH["sDniUsu"],
      "idCasillero": this.ventanaCasH["idCasillero"],
      "idLocker": this.ventanaCasH["idLocker"],
      "idOficina": this.ventanaCasH["idOficina"]
    }
    console.log("DATOS A EDITAR: ", reqOficina);
    var respl = await this.SweetAlertService.swalConfirmLiberar("Si");
    if (respl) {
      this.SweetAlertService.swalLoading();
      var resp = await this.PruebasService.LiberarCasillero(reqOficina);
      if (resp["status"]) {
        await this.SweetAlertService.swalUsuarioActualizado("Éxito","Se ha liberado el casillero");
        await this.CargarLocker();
      }
      else {
        this.SweetAlertService.swalError("No se pudieron realizar los cambios. Intente nuevamente");
      }
      this.SweetAlertService.swalClose();  
   } 
  }



}

<nav class="main-header navbar navbar-expand navbar-white navbar-light nav-mobile">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block title">
          <h3>Sistema de Locker</h3>
        </li>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <!-- Select-sucursal -->
        <form class="form-inline">
          <div class="input-group input-group-sm">
            <select class="form-control form-control-navbar" placeholder="Search" aria-label="Search">
              <option>Sucursal 1</option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-navbar" type="submit">
                <i class="far fa-check-circle"></i>
              </button>
            </div>
          </div>
        </form>
        <!-- Messages Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-user-circle"></i>

          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <img src="assets/img/user.png" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{localData}}

                  </h3>
                  <p class="text-sm">Cargo de usuario</p>
                  <p class="text-sm text-muted"><i class="fas fa-cog"></i> Ajustar perfil</p>
                </div>
              </div>
              <!-- Message End -->
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer"> <i class="fas fa-door-open"></i> Salir</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-light-primary elevation-4">
      <!-- Brand Logo -->
      <a href="index.html" class="brand-link">
        <img src="assets/img/logo.svg" alt="Logo" class="brand-image  " style="opacity: .8">
      </a>
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
            <li class="nav-item menu-open">
              <div  class="nav-link active" (click)="Dashboard()">
                <i class="fas fa-house-user"></i>
                <p>
                  Inicio
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div >
            </li>
            <li class="nav-item">
              <div class="nav-link" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-file-signature"></i>
                <p>
                  Admin Usuarios
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div>
              <div class="dropdown-menu w-100" aria-labelledby="dropdownMenu">
                <button class="dropdown-item" (click)="redireccionTablaClientes()" type="button">Usuarios</button>
                <!-------------- SE ELIMINAN SECTORES ADMINISTRATIVOS PARA METHANEXT ------------------>
                <!-- <button class="dropdown-item" (click)="redireccionTablaUsuarios()" type="button">Administrativos</button>
                <button class="dropdown-item" (click)="redireccionTablaUsuariosOfi()" type="button">Encargados Oficina</button> -->
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-link" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-file-signature"></i>
                <p>
                  Admin Oficinas
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div>
              <div class="dropdown-menu w-100" aria-labelledby="dropdownMenu">
                <button class="dropdown-item" (click)="redireccionTablaOficinas()" type="button">Oficinas</button>
              </div>
            </li>
            <li class="nav-item ">
              <div class="nav-link"  id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
                <i class="fas fa-binoculars"></i>
                <p>
                  Reportería
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div>
              <div class="dropdown-menu w-100" aria-labelledby="dropdownMenu">
                <button class="dropdown-item" (click)="redireccionReporteriaCierre()" type="button">Reporte uso Dispositivo</button>
                <button class="dropdown-item" (click)="redireccionReporteriaLog()" type="button">Reporte de LOG</button>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-link" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-file-signature"></i>
                <p>
                  Admin Elementos
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div>
              <div class="dropdown-menu w-100" aria-labelledby="dropdownMenu">
                <button class="dropdown-item" (click)="redireccionTipoElemento()" type="button">Tipo Elementos</button>
                <button class="dropdown-item" (click)="redireccionElemento()" type="button">Elementos</button>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-link" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-file-signature"></i>
                <p>
                  Carga Masiva
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div>
              <div class="dropdown-menu w-100" aria-labelledby="dropdownMenu">
                <button class="dropdown-item" (click)="redireccionMasivaElementos()" type="button">Carga Masiva Elementos</button>
                <button class="dropdown-item" (click)="redireccionMasivaUsuarios()" type="button">Carga Masiva Usuarios</button>
              </div>
            </li>
            <!-- <li class="nav-item">
              <div  class="nav-link" (click)="RenviarCorreo()" style="cursor: pointer;">
                <i class="fas fa-house-user"></i>
                <p>
                  Reenviar Correo
                  <i class="right fas fa-angle-down"></i>
                </p>
              </div >
            </li> -->
          </ul>
        </nav>
      </div>
    </aside>

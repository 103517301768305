<!-- main app container -->
<div class="card m-3">
    <h5 class="card-header" class="text-secondary">Añadir Nueva Region</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Region</label>
                <input type="number"  formControlName="iReg" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Zona</label>
                <input type="number"  formControlName="iZon" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Nombre Region</label>
                <input type="text"  formControlName="nReg" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Orden</label>
                <input type="number"  formControlName="iOrd" class="form-control">
            </div>
            
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarRegion()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
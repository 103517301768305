<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 62vh!important;">
    <h5 class="card-header" class="text-secondary">Añadir Usuarios</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm">
            <div class="form-group" *ngIf="ListaRoles">
                <label for="exampleInputZone">ID Rol</label>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('sIdRol').errors}" formControlName="sIdRol" >
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option *ngFor="let Rol of ListaRoles" [ngValue]="Rol.idRol">{{
                        Rol.descripcion }}</option>
                </select>
                <div *ngIf="submitted && registerForm.get('sIdRol').errors">
                    <small class="msg-error">Seleccione un rol</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Rut cliente</label>
                <input type="text" [ngClass]="{'is-invalid': submitted && (registerForm.get('sRut').errors || !rutValid)}" formControlName="sRut" class="form-control" maxlength="12" (keyup)="validaRut()">
                <div *ngIf="submitted && (registerForm.get('sRut').errors || !rutValid)">
                    <small class="msg-error" *ngIf="registerForm.get('sRut').errors">Ingrese un rut</small>
                    <small class="msg-error" *ngIf="!registerForm.get('sRut').errors">Ingrese un rut válido</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Nombre</label>
                <input type="text" minlength="3" (keypress)="validateFormat($event)"  [ngClass]="{'is-invalid': submitted && registerForm.get('sNom').errors}" formControlName="sNom" class="form-control">
                <div *ngIf="submitted && registerForm.get('sNom').errors">
                    <small class="msg-error">Ingrese un nombre</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Clave</label>
                <input type="text" [ngClass]="{'is-invalid': submitted && registerForm.get('sClave').errors}" formControlName="sClave" class="form-control" >
                <div *ngIf="submitted && registerForm.get('sClave').errors">
                    <small class="msg-error">Ingrese una clave</small>
                </div>
            </div>
            <div class="form-group" *ngIf="ListaOficinas">
                <label for="exampleInputZone">Oficina</label>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('idOfi').errors}" formControlName="idOfi" >
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option value="1" >--Sin oficina--</option>
                    <option *ngFor="let Ofi of ListaOficinas" [ngValue]="Ofi.idOficina">{{ Ofi.sOficina }}</option>
                </select>
                <div *ngIf="submitted && registerForm.get('idOfi').errors">
                    <small class="msg-error">Seleccione una Oficina</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Estado</label><br>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('eUsuario').errors}" 
                formControlName="eUsuario" placeholder="Selecciona">
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option value="true">Habilitado</option>
                    <option value="false">Deshabilitado</option>
                </select>
                <div *ngIf="submitted && registerForm.get('eUsuario').errors">
                    <small class="msg-error">Seleccione un estado</small>
                </div>
            </div>           
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarCliente()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
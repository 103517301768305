<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-auto">
                    <h1 class="m-0 pl-4 subtitle">Reenvio de Pin</h1>
                </div>
                <!-- /.col -->
                <div class="col-sm-6 mt-1 border-left border-right">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"><a href="#">Locker</a></li>
                        <li class="breadcrumb-item active">Reenvio de Pin de seguridad</li>
                    </ol>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">

            <!-- Main row -->
            <div class="row">
                <!-- Left col -->
                <section class="col-lg-12  connectedSortable">
                    <!-- Custom tabs (Charts with tabs)-->
                    <div class="card box-success box">
                        <div class="card-header">

                            <div class="card-tools">
                                <ul class="nav nav-pills ml-auto">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#informacion-general" data-toggle="tab"><i
                                                class="fas fa-tag"></i> Reenvio de Pin</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-n4">
                            <div class="tab-content p-0">
                                <!-- Morris chart - Sales -->
                                <div class="chart tab-pane active" id="informacion-general">
                                    <h6 class="pt-n2">Campos para reenviar Pin de seguridad</h6>
                                    <div class="row pb-0  ">

                                        <div class="col-sm-6 col-lg-6 col-12">
                                            <div class="box-input">
                                                <div class="row">
                                                    <div class="col-sm-6 col-lg-4 col-12">
                                                        <p class="text-input">Correo de Cliente</p>
                                                    </div>
                                                    <div class="col-sm-6 col-lg-6 col-12"
                                                        style="position: relative; right: 50px;">
                                                        <input (blur)="compexreg_email()"type="text" [(ngModel)]="CorreoCliente"
                                                            placeholder="Indique el correo del cliente" required>
                                                    </div>
                                                    <div class="col-sm-6 col-lg-6 col-12">
                                                       <span class="red">{{emailvalidotext}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-6 col-12" style="position: relative; right: 100px;">
                                            <div class="box-input">
                                                <div class="row">
                                                    <div class="col-sm-6 col-lg-4 col-12">
                                                        <p class="text-input">Orden de Transporte</p>
                                                    </div>
                                                    <div class="col-sm-6 col-lg-6 col-12"
                                                        style="position: relative; right: 35px;">
                                                        <input (blur)="validarOT()" type="text"  [(ngModel)]="OTPedido"
                                                            placeholder="Indique la orden de transporte" required>
                                                    </div>
                                                    <div class="col-sm-6 col-lg-6 col-12">
                                                        <span class="red">{{OTvalidotext}}</span>
                                                     </div>
                                                </div>
                                            </div>
                                            <button  class="mt-2 btn btn-block btn-success float-right" (click)="EnviarPin()">
                                                <i class="fas fa-cogs">
                                                    Reenviar Pin
                                                </i>
                                            </button>
                                        </div>
                                        
                                        <div class="col-12">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /.card-body -->
                        </div>
                        <!-- /.card -->
                    </div>
                    <!-- /.card -->
                </section>
                <!-- /.Left col -->
                <!-- right col (We are only adding the ID to make the widgets sortable)-->
                <!-- right col -->
            </div>
            <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>
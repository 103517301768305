
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-auto">
              <h1 class="m-0 pl-4 subtitle">Resumen Locker</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6 mt-1 border-left border-right">
              <ol class="breadcrumb float-sm-left">
                <li class="breadcrumb-item"><a href="#">Methanext</a></li>
                <li class="breadcrumb-item active">Resumen Locker</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid d-flex">
          <!-- Small boxes (Stat box) -->
          <div class="row d-flex col-4 h-50">
            <div class="col-lg-12 col-sm-12 col-12">
              <!-- small box -->
              <div class="small-box bg-info">
                <div class="inner">
                  <h6>Elementos Usados</h6>
                  <h3 class="num-popp">{{elementosUsados}}</h3>

                  <p>En este dia</p>
                </div>

                <div class="icon">
                  <div class="ico-box"> <img src="/assets/img/herramientas.svg">
                    <!-- <i class="ion ion-bag"></i> -->
                  </div>
                </div>

              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-12 col-sm-12 col-12">
              <!-- small box -->
              <div class="small-box bg-info ">
                <div class="inner">
                  <h6>Elementos Disponibles</h6>
                  <h3 class="num-popp">{{elementosDisponibles}}</h3>
                  <p>El día de hoy</p>
                </div>
                <div class="icon">
                  <div class="ico-box"> <img src="/assets/img/herramientas.svg">

                  </div>
                </div>


              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-12 col-sm-12 col-12">
              <!-- small box -->
              <div class="small-box bg-info ">
                <div class="inner">
                  <h6>Elementos Dañados</h6>
                  <h3 class="num-popp">{{elementosdanadoshoy}}</h3>

                  <p>El día de hoy</p>
                </div>

                <div class="icon">
                  <div class="ico-box"> <img src="/assets/img/herramientasDanadas.svg">

                  </div>
                </div>


              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-12 col-sm-12 col-12">
              <!-- small box -->
              <div class="small-box bg-info ">
                <div class="inner">
                  <h6>Total de Elementos Dañados</h6>
                  <h3 class="num-popp">{{elementosdanados}}</h3>
                  <p>En general</p>
                </div>
                <div class="icon">
                  <div class="ico-box"> <img src="/assets/img/herramientasDanadas.svg">
                  </div>
                </div>
              </div>
            </div>
            <!-- ./col -->
          </div>
          <!-- /.row -->
          <!-- Main row -->
          <div class="row col-sm-12 col-8 col-lg-8">
            <!-- Left col -->
            <section class="col-lg-12  connectedSortable">
              <!-- Custom tabs (Charts with tabs)-->
              <div class="card box-success box">
                <!-- /.card-header -->
                <div class="card-body">
                  <app-locker></app-locker>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
              <!-- /.card -->
            </section>
            <!-- /.Left col -->
            <!-- right col (We are only adding the ID to make the widgets sortable)-->
            <!-- right col -->
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { login } from 'src/app/customer';
import { RutService } from '../../core/services/http/rut.service';
import { DataProcessService } from './../../core/services/http/data-process.service';

@Component({
  selector: 'app-login-usuario',
  templateUrl: './login-usuario.component.html',
  styleUrls: ['./login-usuario.component.scss']
})
export class LoginUsuarioComponent implements OnInit {

  rutValid: boolean;
  login: login[] = [];
  registerForm: FormGroup;
  myGroup: FormGroup;
  email = new FormControl('');
  password = new FormControl('');
  usuarios = null;
  rut = '';
  nombreUsuario = '';
  passUsuario = '';
  estado = true;
  submited: boolean;

  
  
  constructor(
    private router: Router,
    private dataProcessService: DataProcessService,
    private SweetAlertService: SweetAlertService,
    private PruebasService: PruebasService,
    private RutService:RutService,
    private Router: Router) { }

  ngOnInit(): void {
    this.iniciarDatos()
  }

  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  salir()
  {
    this.Router.navigate(['']);
    this.dataProcessService.deleteDataProcess();
  }

  iniciarDatos()
  {
    this.myGroup = new FormGroup({
      usuarioRut: new FormControl(),
      usuarioPass: new FormControl()
     }); 
  }

  get f() { return this.registerForm.controls; }



  async ValidaLogin(){

    this.submited = true;

    if(!this.rutValid || !this.myGroup.valid)
    {
      return;
    }

    let datosInterface : login = {
      rut: this.myGroup.value.usuarioRut.replaceAll('.',''),
      password: this.myGroup.value.usuarioPass
    }
    await this.SweetAlertService.swalLoading();
    console.log('FORMULARIO: ', datosInterface);
    let datosRetornados = await this.PruebasService.loginUsuario(datosInterface)
    console.log('RESPONSE: ',datosRetornados);
    var estadoBtn = true;
    if(datosRetornados.status == true)
    {
      var usuarios = await this.PruebasService.TraerClientesMethanext();
      usuarios = usuarios["data"];
      usuarios.forEach(element => {
        if (element["sDniUsu"].replace(".", "").replace(".", "").replace(".", "").replace("-", "").toLowerCase() == datosInterface.rut.replace(".", "").replace(".", "").replace(".", "").replace("-", "").toLowerCase()) {
          localStorage.setItem('rolsesion',element["idRol"])
        }
        
      });
      estadoBtn = false;
      this.dataProcessService.addDataProcess('rut', datosInterface.rut);
      localStorage.setItem('rut',datosInterface.rut)
      this.SweetAlertService.swalSuccess("Éxito", "Bienvenido Nuevamente");
      this.volver()
    }
    // else if (datosRetornados.code == 401)
    // {
    //   await this.SweetAlertService.swalError("¡Error, Usuario o contraseña incorrectos!");
    //   window.location.reload()
    // }
    // else if (datosRetornados.code == 404)
    // {
    //   await this.SweetAlertService.swalError("¡Error, Los campos no pueden quedar vacíos!");
    //   window.location.reload()
    // }
    else{
      await this.SweetAlertService.swalError("¡Error, Por favor intente nuevamente!");
      window.location.reload()
    }
  }

  validaRut(): void {

    console.log(this.myGroup.value.usuarioRut);
    
    this.rutValid = this.RutService.validarRut(this.myGroup.value.usuarioRut.replace(/\./g, '').replace('-', ''));
    this.myGroup.patchValue({ usuarioRut: this.RutService.formateaRut(this.myGroup.value.usuarioRut) });
  }

  TablaUser(): void {
    //e.preventDefault();
    this.router.navigate(['MenuOpciones']);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable()
export class UploadService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Auth dHRwOjIwMjAu',
    });

    constructor(private httpClient: HttpClient, private configService: ConfigService,) 
    { }

    async uploadFileElementos(file: File): Promise<Observable<HttpEvent<any>>> {
    
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['DatosMethanex']}Elementos/CargarLista`;
        
       return this.uploadFile(url, file);
    }

    async uploadFileUsuarios(file: File): Promise<Observable<HttpEvent<any>>> {
    
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['DatosMethanex']}Usuarios/CargarLista`;
          
         return this.uploadFile(url, file);
    }

    uploadFile(url: string, file: File): Observable<HttpEvent<any>> {        
        let formData = new FormData();
        let params = new HttpParams();

        formData.append('upload', file);        

        const options = {
            params: params,
            reportProgress: false,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClient.request(req);
    }
}

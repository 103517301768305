import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PesoChilenoPipe } from '../../../core/pipes/peso-chileno.pipe';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import {ReporteriaService} from '../../../core/services/http/reporteria.service';
//exportar a excel
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//exportar a pdf
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'


@Component({
  selector: 'app-reporte-cierre',
  templateUrl: './reporte-cierre.component.html',
  styleUrls: ['./reporte-cierre.component.scss']
})
export class ReporteCierreComponent implements OnInit {
  dataCierre: any[] = [];
  nameTabla!: string;
  columns: any[] = [];
  exportColumns: any[];
  cols: any[];
  fileName= 'ReporteDispositivo.xlsx';

  constructor(
    private sweetAlertService: SweetAlertService,
    private pesoChilenoPipe: PesoChilenoPipe,
    private datePipe: DatePipe,
    private reporteriaService: ReporteriaService
    // private pagosService: PagosService
  ) { }

  ngOnInit(): void {
    this.getColumnas();
  }
  
  getColumnas(): void {
    this.columns = [
      { title: 'Oficina', data: 'estadoPedido', className: 'dt-center' },
      { title: 'Fecha', data: 'fecha', className: 'dt-center'},
      { title: 'Usuario', data: 'nombreUsuario',className: 'dt-center' },
      { title: 'RUT', data: 'sDniUsu',className: 'dt-center' },
      { title: 'Casillero', data: 'idCasillero',className: 'dt-center' },
      { title: 'Dispositivo', data: 'nombreElemento',className: 'dt-center' },
      { title: 'Serial', data: 'serial', className: 'dt-center'},
      { title: 'Accion', data: 'accion',render: (data: any): any => {return this.definiraccion(data)}, className: 'dt-center' }
    ];
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }
  
  definiraccion(accion){
    if (accion== "liberar") {
      return "Disp  retirado"
    }
    else if(accion== "cargar"){
      return "Carga Admin"
    }
    else if(accion== "usar"){
      return "Retiro para uso"
    }
    else if(accion== "notificar"){
      return "Disp Dañado"
    }
    else if(accion== "retirar"){
      return "Disp en reparacion"
    }
    else if(accion== "reponer"){
      return "Disp reparado"
    }
    else if(accion== "Devolver"){
      return "Disp Devuelto"
    }
    else {
      return accion
    }
  }

  async getTransaction(data: any): Promise<void> {
    console.log("CIERRE: ");
    
    this.sweetAlertService.swalLoading();
    this.dataCierre = [];
    const response = await this.reporteriaService.ReporteCargaDesc(data);
    this.sweetAlertService.swalClose();
    console.log("response ", response);
    

    if (response['status']) {
      this.dataCierre = response['data'];
      const totem = data.idTotem === 0 ? '' : `_Kiosko-${data.idTotem}`;
      this.nameTabla = `Reporte de Uso ${data.fechaFin}`;
    }
  }
  //EXPORTAR A EXCEL (SE FILTRAN LOS DATOS A IMPRIMIR)
  exportExcel() {
    var datos = [];
    var toExport = [];
    datos = this.dataCierre;
    for (let element of datos) {
      var obj = {
        'Oficina':element.estadoPedido,
        'Fecha': element.fecha,
        'Usuario': element.nombreUsuario,
        'RUT': element.sDniUsu,
        'Casillero': element.idCasillero,
        'Dispositivo': element.nombreElemento,
        'Serial': element.serial,
        'Acción': element.accion
      }
      toExport.push(obj);
    }
    import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(toExport);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "ReporteDispositivo");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  //EXPORTAR A PDF
  exportPdf() {
    var datos = [];
    var toExport = [];
    datos = this.dataCierre;
    for (let element of datos) {
      var obj = {
        'Oficina':element.estadoPedido,
        'Fecha': element.fecha,
        'Usuario': element.nombreUsuario,
        'RUT': element.sDniUsu,
        'Casillero': element.idCasillero,
        'Dispositivo': element.nombreElemento,
        'Serial': element.serial,
        'Acción': element.accion
      }
      toExport.push(obj);
    }
     var doc = new jsPDF('p', 'pt');
     doc.text("Reporte de uso Dispositivos",50, 30);
      autoTable(doc, {columns: this.exportColumns,body: toExport})
      doc.save('ReporteDispositivo.pdf');
  }

}

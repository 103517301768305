<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<link href="//netdna.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.2.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-2.1.3.min.js"></script>
<!------ Include the above in your HEAD tag ---------->


<div class="container" style="color: black;" [formGroup]="myGroup" div class="container-fluid overflow-auto" style="height: 87vh!important;">
  <button (click)="volver()">Volver</button>
        <div class="form-group">
          <label for="exampleInputPassword1">Ingrese la contraseña del casillero</label>
          <input type="password" id="clave" formControlName="passWrd" class="form-control" name="password">

          <label for="exampleInputPassword1"></label>
          <input type="hidden" id="idCasillero" formControlName="idCasille" class="form-control" name="casillerO"> 

          <label for="exampleInputPassword1"></label>
          <input type="hidden" id="idLocker" formControlName="idLock" class="form-control" name="lockeR">

          <label for="exampleInputPassword1"></label>
          <input type="hidden" id="idOficina" formControlName="idOfic" class="form-control" name="oficinA">



        </div>
        <button type="submit" class="btn btn-primary" (click)="retirarPedido()">CONFIRMAR</button>
</div>
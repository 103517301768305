import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { UsuariosOfi } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-lockers-modal',
  templateUrl: './lockers-modal.component.html',
  styleUrls: ['./lockers-modal.component.scss']
})
export class LockersModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  //@Input() usuOfi;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  iniciarDato() {
    this.registerForm = new FormGroup({
      idLocker: new FormControl(),
      idOficina: new FormControl(),
      iNumPuertas: new FormControl(),
      bHab: new FormControl(),
      nomLocker: new FormControl(),
      direccion: new FormControl(),
      comuna: new FormControl(),
      latitud: new FormControl(),
      longitud: new FormControl(),
    });
  }
  obtenerdatos() {
    console.log(this.registerForm.value);
    this.datosInterface = {
      "acc": "N",
      "idL": this.registerForm.value.idLocker,
      "idO": this.registerForm.value.idOficina,
      "nPuertas": parseInt(this.registerForm.value.iNumPuertas),
      "hab": this.registerForm.value.bHab === "true" || this.registerForm.value.bHab === true? true : false,
      "sNomLocker":this.registerForm.value.nomLocker,
      "sDireccion": this.registerForm.value.direccion,
      "idComuna": this.registerForm.value.comuna,
      "sLatitud": "0",
      "sLongitud": "0",
      "iLuhi": 9,
      "iLumi": 0,
      "iLuhf": 21,
      "iLumf": 0,
      "iMahi": 9,
      "iMami": 0,
      "iMahf": 21,
      "iMamf": 0,
      "iMihi": 9,
      "iMimi": 0,
      "iMihf": 21,
      "iMimf": 0,
      "iJuhi": 9,
      "iJumi": 0,
      "iJuhf": 21,
      "iJumf": 0,
      "iVihi": 9,
      "iVimi": 0,
      "iVihf": 21,
      "iVimf": 0,
      "iSahi": 9,
      "iSami": 0,
      "iSahf": 12,
      "iSamf": 0,
      "iDohi": 9,
      "iDomi": 0,
      "iDohf": 12,
      "iDomf": 0
    }
    console.log('Datos Interfaz: ', this.datosInterface);
  }
    async agregarLockers() {

    await this.obtenerdatos();
    console.log(this.datosInterface);
    
    // let datosRetornados = await this.PruebasService.crudLockers(this.datosInterface)
    // console.log('RESPONSE: ', datosRetornados);
    // if (datosRetornados.status == true) {
    //   this.SweetAlertService.swalSuccess("Locker creado!");    
    //   this.cerrarModal();
    // }
    // else {
    //   this.SweetAlertService.swalError("¡No se puedo crear el locker, intente nuevamente!");
    // }
  }

}

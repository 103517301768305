import { Component, Input, OnInit } from '@angular/core';
import {SweetAlertService} from '../../core/services/sweet-alert/sweet-alert.service';
import {NuevosPedidos} from '../../../app/customer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/core/services/config/config.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {PruebasService} from '../../core/services/http/pruebas.service';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-nuevo-pedido',
  templateUrl: './nuevo-pedido.component.html',
  styleUrls: ['./nuevo-pedido.component.scss']
})
export class NuevoPedidoComponent implements OnInit {

   
   pedidoss: NuevosPedidos[]
   pedidossV2: NuevosPedidos[] = [];
   router: any;
   registerForm: FormGroup;
   submitted: boolean = false;
   myGroup: FormGroup;

   
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(private configService: ConfigService,
    private httpClient: HttpClient, private Router:Router, 
    private PruebasService: PruebasService, private modalService: ModalService, 
    private formBuilder: FormBuilder, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.iniciarDatos()
  }

  volver() {
    this.Router.navigate(['MenuOpciones']);
  }
  


  iniciarDatos()
  {
    this.myGroup = new FormGroup({
     
      idLocker : new FormControl(),
      idOfi : new FormControl(),
      idCas: new FormControl(),
      rutCli: new FormControl(),
      rutUsu: new FormControl(),
      email: new FormControl()
  
     }); 
  }

  get f() { return this.registerForm.controls; }

  
  


  async cargarDatos()
  {
    /*this.myGroup.patchValue({
      idLocker: this.myGroup.value.idL,
      idOfi: this.myGroup.value.idO,
      idCas: this.nuevosPedidos.idCa,
      rutCli: this.nuevosPedidos.dniCli,
      rutUsu: this.nuevosPedidos.dniUsu,
      codSku: this.nuevosPedidos.sku,
      email: this.nuevosPedidos.correo
    })*/
    let datosInterface : NuevosPedidos = {
      idL: this.myGroup.value.idLocker,
      idO: this.myGroup.value.idOfi,
      idCa: this.myGroup.value.idCas,
      dniCli: this.myGroup.value.rutCli,
      dniUsu: this.myGroup.value.rutUsu,
      correo: this.myGroup.value.email,
       
    }
    let datosRetornados = await this.PruebasService.IngresarPedido(datosInterface)
    var estadoBtn = true;
    if(datosRetornados.status == true)
    {
      estadoBtn = false;
      await this.SweetAlertService.swalSuccess("Éxito","¡Pedido pre cargado correctamente 📦!");
      this.volver()
    }
    else if(datosRetornados.code == 401)
    {
      await this.SweetAlertService.swalError("¡ID OFICINA no habilitada, intente con otro ID por favor!");
      window.location.reload()
    }
    else if(datosRetornados.code == 402)
    {
      await this.SweetAlertService.swalError("¡ID LOCKER no habilitado, intente con otro ID por favor!");
      window.location.reload()
    }
    else if(datosRetornados.code == 403)
    {
      await this.SweetAlertService.swalError("¡ID CASILLERO no disponible, intente con otro ID por favor!");
      window.location.reload()
    }
    else if(datosRetornados.code == 404)
    {
      await this.SweetAlertService.swalError("¡Error, Rut de cliente no existe!");
      window.location.reload()
    }
    else if(datosRetornados.code == 405)
    {
      await this.SweetAlertService.swalError("¡Error, Rut del usuario no existe!");
      window.location.reload()
    }
    else if(datosRetornados.code == 804)
    {
      await this.SweetAlertService.swalError("¡Error, Los campos no pueden quedar vacíos!");
      window.location.reload()
    }
    else if(datosRetornados.code == 406)
    {
      await this.SweetAlertService.swalError("¡Error, Correo no puede quedar vacío!");
      window.location.reload()
    }
    
    

    

  }






}

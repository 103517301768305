<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 25vh!important;">
    <h5 class="card-header" class="text-secondary">Añadir Bodega</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Bodega</label>
                <input type="text"  formControlName="idBode" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Nombre Bodega</label>
                <input type="text"  formControlName="sBode" class="form-control" >
            </div>               
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarBodega()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
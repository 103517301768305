import { Component, OnInit } from '@angular/core';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-reenviar-correo',
  templateUrl: './reenviar-correo.component.html',
  styleUrls: ['./reenviar-correo.component.scss']
})
export class ReenviarCorreoComponent implements OnInit {

  CorreoCliente;
  OTPedido;

  emailvalidotext
  OTvalidotext

  constructor(private SweetAlertService:SweetAlertService) { }

  ngOnInit(): void {
    this.OTPedido = "";
    this.CorreoCliente ="";
  }

  compexreg_email() {
    var valido = true;
    if (this.CorreoCliente != "") {
      if (!/^([a-zA-Z0-9\._-])+@{1}([a-zA-Z0-9])+\.{1}([a-zA-Z]){2,3}$/.test(this.CorreoCliente)) {
        this.emailvalidotext = "Formato de correo invalido";
        valido = false;
      }
      else {
        this.emailvalidotext = "";
      }
    }
    else {
      this.emailvalidotext = "El correo no puede estar vacio";
      valido = false;
    }

    return valido;
  }

  validarOT(){
    var valido = true;
    if (this.OTPedido != "") {
      if (this.OTPedido.length < 13 || this.OTPedido.length > 21 ) {
        this.OTvalidotext = "Formato de OT invalido debe ser un numero entr 13 y 21 digitos";
        valido = false;
      }
      else {
        this.OTvalidotext = "";
      }
    }
    else {
      this.OTvalidotext = "La OT no puede estar vacia";
      valido = false;
    }

    return valido;
  }

  EnviarPin(){
    var respemail = this.compexreg_email();
    if (this.validarOT() && respemail) {
      this.SweetAlertService.swalSuccess("Éxito","Pin Reenviado Correctamente");
    }
    else{
      this.SweetAlertService.swalError("Los campos deben estar debidamente llendos");
    }
    
  }

}

<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <h5 class="card-header" class="text-secondary">Añadir Comuna</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Comuna</label>
                <input type="number"  formControlName="iCom" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Region</label>
                <input type="number"  formControlName="iReg" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Nombre Comuna</label>
                <input type="text"  formControlName="nCom" class="form-control" >
            </div>           
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarComuna()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
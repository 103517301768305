import { Component, OnInit } from '@angular/core';
import { UploadService } from '../../../core/services/http/upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-masiva-usuarios',
  templateUrl: './masiva-usuarios.component.html',
  styleUrls: ['./masiva-usuarios.component.scss']
})
export class MasivaUsuariosComponent implements OnInit {

  uploadingFile: boolean;
  cargarArchivo:boolean=false;

  constructor(
        private uploadService: UploadService,
        private SweetAlertService: SweetAlertService,
  ) { }

  ngOnInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  async fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length === 0) {
        return;
    }

    let file: File = fileList[0];
    this.uploadingFile = true;
    this.SweetAlertService.swalLoading();
    (await this.uploadService.uploadFileUsuarios(file)).subscribe(async result => 
        {
            if (result instanceof HttpResponse) {
                await this.SweetAlertService.swalSuccess("Éxito", result["body"]["message"]);
                console.log("RESPUESTA: ", result );
            }
        },
        error => {
            console.error(error);
            this.uploadingFile = false;
            this.SweetAlertService.swalError("No se pudo cargar el archivo, intente nuevamente.");
        },
        () => {
            this.uploadingFile = false;
        }
    )
}

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Bodegas} from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { RegionesModalComponent } from '../admi-regiones/regiones-modal/regiones-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {BodegasModalComponent} from './bodegas-modal/bodegas-modal.component';

@Component({
  selector: 'app-admi-bodega',
  templateUrl: './admi-bodega.component.html',
  styleUrls: ['./admi-bodega.component.scss']
})
export class AdmiBodegaComponent implements OnInit {

  bodega: Bodegas[]
  bodegasV2: [] = [];
  myGroup: FormGroup;
  datosInterface: Bodegas;
  selectedProducts: Bodegas[];
  modalRef: BsModalRef;
  loading;

  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {

    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos() {
    this.myGroup = new FormGroup({
      iBod: new FormControl(),
      sBod: new FormControl()
    });
  }

  async traerdatos(datos) {
    console.log(datos);
    var reqBodega = {
      "acc": "U",
      "idBodgea": datos.idBodega,
      "sBodega": datos.sBodega
    }
    var resp = await this.PruebasService.AdministracionBodegas(reqBodega);
    if (resp["status"]) {
      this.bodegasV2 = resp['data'];
      console.log(resp['data']);
    }
    else {
    }
  }

  async agregarDatos(datos) {
    console.log(datos);
    var reqBodega = {
      "acc": "U",
      "idBodgea": datos.idBodega,
      "sBodega": datos.sBodega
    }
    var resp = await this.PruebasService.AdministracionBodegas(reqBodega);
    if (resp["status"]) {
      this.bodegasV2 = resp['data'];
      console.log(resp['data']);
    }
    else {

    }
  }

  async llenarTabla() {
    let response = await this.PruebasService.traerCasilleros();
    if (response['status']) {
      this.bodegasV2 = response['dataBod'];
      console.log("Bodegas:", this.bodegasV2);
    }
  }

  Seleccionar() {
    const opcion = { class: 'w-50', initialState: { regiones: this.bodegasV2 } }
    this.modalRef = this.modalService.openModal(BodegasModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if (result) {
        this.llenarTabla()
      }

    });
  }



}

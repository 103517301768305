import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Casilleros } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { RegionesModalComponent } from '../admi-regiones/regiones-modal/regiones-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CasillerosModalComponent } from './casilleros-modal/casilleros-modal.component';

@Component({
  selector: 'app-admi-casilleros',
  templateUrl: './admi-casilleros.component.html',
  styleUrls: ['./admi-casilleros.component.scss']
})
export class AdmiCasillerosComponent implements OnInit {

  casillero: Casilleros[]
  casilleroV2: [] = [];
  myGroup: FormGroup;
  datosInterface: Casilleros;
  selectedProducts: Casilleros[];
  modalRef: BsModalRef;

  loading;

  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {

    this.iniciarDatos()
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  iniciarDatos() {
    this.myGroup = new FormGroup({
      idLock: new FormControl(),
      idOfi: new FormControl(),
      idCasi: new FormControl(),
      sTipos: new FormControl(),
      idAltura: new FormControl(),
      idLargo: new FormControl(),
      sEstados: new FormControl()
    });
  }

  async traerdatos(datos) {
    console.log(datos);
    var reqCasillero = {
      "acc": "U",
      "idL": parseInt(datos.idLocker),
      "idO": parseInt(datos.idOficina),
      "idCa": parseInt(datos.idCasillero),
      "tipo": datos.sTipo,
      "alto": parseInt(datos.iAlto),
      "ancho": parseInt(datos.iAncho),
      "largo": parseInt(datos.iLargo),
      "estado": datos.sEstado
    }
    var resp = await this.PruebasService.ActualizarCasillero(reqCasillero);
    if (resp["status"]) {
      this.casilleroV2 = resp['data'];
      console.log(resp['data']);
    }
    else {
    }
  }

  async agregarDatos(datos) {
    console.log(datos);
    var reqCasillero = {
      "acc": "U",
      "idL": datos.idLocker,
      "idO": datos.idOficina,
      "idCa": datos.idCasillero,
      "tipo": datos.sTipo,
      "alto": datos.iAlto,
      "ancho": datos.iAncho,
      "largo": datos.iLargo,
      "estado": datos.sEstado
    }
    var resp = await this.PruebasService.ActualizarCasillero(reqCasillero);
    if (resp["status"]) {
      this.casilleroV2 = resp['data'];
      console.log(resp['data']);

    }
    else {

    }
  }

  async llenarTabla() {
    let response = await this.PruebasService.traerCasilleros();
    if (response['status']) {
      this.casilleroV2 = response['dataCas'];
      console.log("Casilleros:", this.casilleroV2);
    }
  }


  Seleccionar() {
    const opcion = { class: 'w-50', initialState: { regiones: this.casilleroV2 } }
    this.modalRef = this.modalService.openModal(CasillerosModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if (result) {
        this.llenarTabla()
      }

    });
  }
}

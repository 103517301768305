import { Component, OnInit } from '@angular/core';
import { interval, Subscription, from } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { CanActivate, Router } from '@angular/router';
import { ApiLockerService } from '../../core/services/http/api-locker.service';
import { TarjetaLockerService } from '../../core/services/http/tarjeta-locker.service';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { ModalTecladoService } from 'src/app/core/services/modals/modal-teclado.service';
import { KeyboardComponent } from 'src/app/custom/keyboard/keyboard.component';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { FormControl } from '@angular/forms';
declare var $;



@Component({
  selector: 'app-escanear-qr',
  templateUrl: './escanear-qr.component.html',
  styleUrls: ['./escanear-qr.component.scss']
})
export class EscanearQRComponent implements OnInit {


  CodigoBarra = "";
  pedidos;
  fecha;
  clave;
  subscription: Subscription = new Subscription();
  user = new FormControl('');
  Retiroclave = false;
  pedidoencontrado;

  constructor(
    private TarjetaLockerService: TarjetaLockerService,
    private ApiLockerService: ApiLockerService,
    private SweetAlertService: SweetAlertService,
    private Router: Router,
    private modalTecladoService:ModalTecladoService,
    private emitTecladoService: EmitTecladoService) { }

  ngOnInit(): void {
    this.keyboardSubscription();
    this.traerPedidos();
    this.TarjetaLockerService.Conectar();
    
    var esto = this;
    window.dispatchEvent(new Event('resize'));
    $(document).ready(function () {
      //console.log("ready!");

      $(document).scannerDetection({
        timeBeforeScanTest: 500, // wait for the next character for upto 200ms
        avgTimeByChar: 200, // it's not a barcode if a character takes longer than 100ms
        onComplete: function (barcode, qty) {
          esto.SweetAlertService.swalLeyendoQR();
          esto.UsarQR(barcode);
        } // main callback function	
      });
    });
  }

  async UsarQR(barcode) {
    this.CodigoBarra = barcode;
    //console.log(barcode);
    var pedido = this.pedidos.filter(function (array) {
      if (array.productos[0].codigoEtiqueta == barcode) {
        return array
      }
    });
    //console.log(pedido);
    if (pedido.length > 0) {
      await this.gethoras();

      if (pedido[0].idEstadoPedido == 1) {
        this.cargarPedido(pedido, barcode)
      }
      if (pedido[0].idEstadoPedido == 2 || pedido[0].idEstadoPedido == 3) {
        this.pedidoencontrado = pedido;
        this.SweetAlertService.swalClose();
        this.Retiroclave = true;
      }
    }
    else {
      var resp = await this.SweetAlertService.SinProducto("El codigo de producto escaneado no se encuentra regiatrado");
      this.Router.navigate(['']);
      //console.log(resp);

    }
  }

  async cargarPedido(pedido, barcode) {
    var carga = await this.SweetAlertService.swalConfirmCarga("SI");
    if (carga == true) {
      //console.log("entro ingreso");
      pedido[0].rutAsignador = 1;
      pedido[0].ordenCompra = barcode;
      pedido[0].fechaCarga = this.fecha;
      this.SweetAlertService.CargandoDatos();
      var puerta = {
        "idPuerta": pedido[0].casilleros[0].idCasillero
      }
      //console.log(puerta);
      
      var resppuerta = await this.TarjetaLockerService.AbrirPuerta(puerta);
      //resppuerta["status"]
      //console.log(resppuerta);
      
      if (true) {
        await this.ApiLockerService.CargarPedidos(pedido[0]);
        this.SweetAlertService.swalClose();
        localStorage.setItem("casillero", pedido[0].casilleros[0].idCasillero);
        this.Router.navigate(['usuario']);
      }
      else {
        await this.SweetAlertService.ErrorPuerta("");
        this.Router.navigate(['']);
      }

    }
    else {
      this.Router.navigate(['']);
    }
  }
  async RetirarPedido(pedido) {
    var retiro = await this.SweetAlertService.swalConfirmRetiro("SI");
    if (retiro == true) {
      pedido[0].idEstadoPedido = 6;
      pedido[0].fechaRetiro = this.fecha;
      pedido[0].rutRetirador = pedido[0].rutComprador;
      //console.log(pedido[0]);
      this.SweetAlertService.CargandoDatos();
      var puerta = {
        "idPuerta": pedido[0].casilleros[0].idCasillero
      }
      //console.log(puerta);
      var resppuerta =  await this.TarjetaLockerService.AbrirPuerta(puerta);
      //console.log(resppuerta);
      
      if (resppuerta["status"]) {
        this.ApiLockerService.registrarRetiro(pedido[0]);
        this.SweetAlertService.swalClose();
        localStorage.setItem("casillero", pedido[0].casilleros[0].idCasillero);
        this.Router.navigate(['cliente']);
      }
      else {
        await this.SweetAlertService.ErrorPuerta("");
        this.Router.navigate(['']);
      }
    }
    else {
      this.Router.navigate(['']);
    }

  }

  async traerPedidos() {
    var respuesta = await this.ApiLockerService.TraerPedidos();
    //console.log(respuesta);
    this.pedidos = respuesta.filter(function (array) {
      if (array.idEstadoPedido != 6) {
        return array
      }
    });
    //console.log(this.pedidos);
    //this.UsarQR("134880953");
    
  }

  gethoras() {
    let date = new Date()
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hora = (date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours())
      + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes())
      + ":" + (date.getSeconds() < 10 ? "0" + date.getSeconds() : "" + date.getSeconds())
      + "." + date.getUTCMilliseconds();
    if (month < 10) {
      this.fecha = `${year}-0${month}-${day}T${hora}`;
    } else {
      this.fecha = `${year}-${month}-${day}T${hora}`;
    }

    //console.log(this.fecha);
  }

  volver() {
    this.Router.navigate(['']);
  }

  keyboardSubscription(): void {
    this.subscription.add(this.emitTecladoService.dataInput.subscribe(data => {
      if (data.input === 'inputUser') {
        this.user.setValue(data.texto.value);
      } 
    }));

  }

  desplegarAlphaNumeric(model: string): void {
    const options = { class: 'mt-5', initialState: {} };

    this.modalTecladoService.openModal(KeyboardComponent, options);

    this.emitTecladoService.enviarModel({
      input: model,
      teclado: 'alphaNumerico',
      texto: this.clave,
      inputType: 'text',
      max: 15, // validar largo contraseña
    });
  }

  desplegarRut(model: string): void {
    const options = { class: 'mt-5 p-5', initialState: { } };

    this.modalTecladoService.openModal(KeyboardComponent, options);

    this.emitTecladoService.enviarModel({
      input: model,
      teclado: 'alphaNumerico',
      texto: this.user.value,
      inputType: 'text',
      max: 20,
    });
  }

  CompararClave(){
    if (this.user.value == this.pedidoencontrado[0].productos[0].codigoEtiqueta) {
      this.RetirarPedido(this.pedidoencontrado);
      this.Retiroclave = false;
    }
    else{
     this.SweetAlertService.swalErrorgeneral("Clave incorrecta","La clave ingresada no corresponde con la asociada a su pedido. Por favor intente nuevamente");
    }
  }



}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { TipoElemento } from '../../../../customer';
import { PruebasService } from '../../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-elemento-modal',
  templateUrl: './elemento-modal.component.html',
  styleUrls: ['./elemento-modal.component.scss']
})
export class ElementoModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  //datosInterface;
  onClose: Subject<boolean> = new Subject();
  TipoElementos : [] = [];
  sesionrol;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private SweetAlertService: SweetAlertService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.sesionrol = localStorage.getItem('rolsesion')
    this.SweetAlertService.swalLoading();
    this.iniciarDato();
    await this.obtenerTipoElemento();
    this.SweetAlertService.swalClose();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  async obtenerTipoElemento() {
    var tipoElemento = await this.PruebasService.TraerTipoElemento();
    if (tipoElemento["status"] == true) {
      this.TipoElementos = tipoElemento["data"];
    }
    else{
      this.SweetAlertService.swalClose();
      this.SweetAlertService.swalError("no se pueden crear elementos en este momento, vuelva a intentar");
    }

    console.log("DATA: ", this.TipoElementos);
    
  }

  iniciarDato() {
    this.registerForm = this.formBuilder.group({
      idTipo: [null, Validators.required],
      serial: ['', Validators.required],
      estado: [null, Validators.required]
    });
  }


  // obtenerdatos() {
  //   console.log(this.registerForm.value);
  //   this.datosInterface = {
  //     "idTipo": parseInt(this.registerForm.value.idTipo),
  //     "serial": this.registerForm.value.serial,
  //     "estado": this.registerForm.value.estado
  //   }
  //   console.log('Datos Interfaz: ', this.datosInterface);
  // }




  async agregarCliente() {

    this.submitted = true;
    if(this.registerForm.invalid){return;}

    this.SweetAlertService.swalLoading();

    const data = {
        "idTipo": parseInt(this.registerForm.value.idTipo),
        "serial": this.registerForm.value.serial,
        "estado": this.registerForm.value.estado
    }

    //await this.obtenerdatos();
    //console.log(this.datosInterface);
    const datosRetornados = await this.PruebasService.AgregarElemntos(data);
    console.log('RESPONSE: ', datosRetornados);
    this.SweetAlertService.swalClose();
    if (datosRetornados.status) {
      this.SweetAlertService.swalSuccess("Éxito","Elemento Creado!");
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se pudo crear elemento, intente nuevamente!");
    }
  }

}


<div class="card box-success box-info" *ngIf="casilleroHover == true">
    <!-- /.card-header -->
    <div class="card-body">
        <div class="card-deck">
            <div class="card">
                <img src="..." class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title font-weight-bold">Casillero N° {{ventanaCasH["idCasillero"]}}</h5>
                    <p class="card-text"><span class="font-weight-bold">Estado:</span> {{ventanaCasH["sEstado"]}}</p>
                    <p class="card-text"><span class="font-weight-bold">Elemento Asociado:</span>
                        {{ventanaCasH["serial"]}}</p>
                    <p class="card-text"><span class="font-weight-bold">Tipo de elemento:</span>
                        {{ventanaCasH["nombreElemento"]}} </p>
                    <p class="card-text"><span class="font-weight-bold">Fecha de carga:</span> {{ventanaCasH["fecha"] |
                        date:'short':'IST' }}</p>
                    <p class="card-text"><span class="font-weight-bold">Rut Cargador:</span> {{ventanaCasH["sDniUsu"]}}
                    </p>
                    <p class="card-text"><span class="font-weight-bold">Nombre Cargador:</span>
                        {{ventanaCasH["nombreUsuario"]}}</p>
                </div>
                <div class="card-footer">
                    <small class="text-muted">Cargado 2 Horas atras</small>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex cntr-stado-locker">
    <div class="font-weight-bold">
        Vista Online Locker
    </div>
</div>
<div class="d-flex flex-column marginlockr">
    <div class="row separacionL">
        <div *ngFor="let columns of Locker let indexi = index" class="columnalocker border-t border-b">
            <div *ngFor="let casillero of columns.Casilleros let indexj = index"
                [ngClass]="{'border-f': indexi == 0,'border-l': indexi == Locker.length-1}">
                <div class="casilleroLocker" *ngIf="casillero.ocupado" (mouseover)="hovercas(casillero.id)"
                    (mouseout)="leavecas(casillero.id)"
                    [ngClass]="{ 'CaOXS': casillero.tama == 'xs','CaOS': casillero.tama == 's','CaOM': casillero.tama == 'm','CaOL': casillero.tama == 'l','CaOXL': casillero.tama == 'xl','CaEquipo': casillero.tama == 'admin'}">
                    <div (click)="Liberar()" class="icons-retiro icono" style="cursor: pointer;" data-toggle="tooltip" data-placement="right" title="LIBERAR CASILLERO">
                        <i class="fas fa-box"></i>
                        <i class="fas fa-hands"></i>
                    </div>
                    <div class="idcasi">{{casillero.id}}</div>
                </div>
                <div class="casilleroLocker" *ngIf="!casillero.ocupado" (mouseover)="hovercas(casillero.id)"
                    (mouseout)="leavecas(casillero.id)"
                    [ngClass]="{ 'CaXS': casillero.tama == 'xs','CaS': casillero.tama == 's','CaM': casillero.tama == 'm','CaL': casillero.tama == 'l','CaXL': casillero.tama == 'xl','CaEquipo': casillero.tama == 'admin'}">
                    <div class="idcasi">{{casillero.id}}</div>
                </div>

            </div>
        </div>
    </div>

    <div class="d-flex cntr-stado-locker">
        <div class="d-flex w-50 align-items-center justify-content-center">
            <div class="Libre">
            </div>
            <div class="font-weight-bold">
                Disponible
            </div>
        </div>

        <div class="d-flex w-50 align-items-center justify-content-center">
            <div class="Ocupado">
            </div>
            <div class="font-weight-bold">
                No Disponible
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Component({
  selector: 'app-select-tipo-usu',
  templateUrl: './select-tipo-usu.component.html',
  styleUrls: ['./select-tipo-usu.component.scss']
})
export class SelectTipoUsuComponent implements OnInit {

  constructor(private Router:Router) { }

  ngOnInit(): void {
  }

  volver() {
    this.Router.navigate(['']);
  }
  IngresarPedido(){
    this.Router.navigate(['/usuario']);
  }
}
